import React, { useContext, useEffect } from "react";
import Container from "../../components/ui/Container";
import FullNameEdit from "./FullNameEdit";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import modal from "../../services/modalServices";

const UserInfoEdits = () => {
    const { user, isFullName } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (user.is_full_name_changed) {
            modal.message({
                title: "مينفعش تغير اسمك للأسف",
                text: "غير مسموح ليك انك تغير اسمك أكتر من مرة",
                icon: "error",
                callback: () => {
                    navigate("/me/user");
                },
            });
        }
    }, [user, navigate]);
    return (
        <>
            <div className="flex flex-col space-y-10">
                <section className="flex flex-col  space-y-10 px-10">
                    <h1 className="text-2xl sm:text-2xl md:text-4xl leading-normal">
                        تــعديل بيانات الأسم بالكامل
                    </h1>
                    <p className="w-full lg:w-1/2  text-md text-right text-slate-700 dark:text-slate-100">
                        لضمان حقك في الحجز بالسنتر برجاء تعديل بيانات الأسم رباعي , ممنوع إستخدام أي
                        لغة أخري , رقم أو رمز
                        <br />
                        ورجاء تأكد من هاتف ولي أمرك وتعديله إذا لزم الأمر
                    </p>
                </section>

                <Container>
                    <FullNameEdit user={user} />
                </Container>
            </div>
        </>
    );
};

export default UserInfoEdits;
