import React, { useContext } from "react";
import TeacherContext from "../../context/TeacherContext";
import { isObjectEmpty } from "../../utils/objects";
import CourseDescItem from "../../components/items/CourseDescItem";
import { printUnit } from "../../utils/ar";
import { baseURL } from "../../config";

const TeacherInfoSection = () => {
    const { teacher } = useContext(TeacherContext);

    return (
        <>
            {!isObjectEmpty(teacher) && (
                <div className="w-full glassy smooth clr-text-primary shadow-large rounded-lg overflow-hidden">
                    <div className="p-4 space-y-8">
                        <div className="overflow-hidden rounded-md">
                            <img src={`${baseURL}/${teacher.picture}`} alt="course" />
                        </div>

                        <div className="font-small">
                            <CourseDescItem
                                title="عدد محاضرات السنتر"
                                value={`+ ${teacher.center_courses_count}`}
                                valueName={
                                    printUnit(teacher.center_courses_count, "محاضرة").split(" ")[1]
                                }
                                icon="ant-design:question-circle-twotone"
                                isLast={true}
                            />
                            <CourseDescItem
                                title="عدد الأكواد"
                                value={`+ ${teacher.api_courses_count}`}
                                valueName={
                                    printUnit(teacher.api_courses_count, "كود").split(" ")[1]
                                }
                                icon="icon-park-twotone:time"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TeacherInfoSection;
