import React, { useContext, useState } from "react";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";

import Button from "../../components/ui/Button";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
// import Waleed from "../../components/svgs/Waleed";
// import YearsBooks from "../../components/svgs/YearsBooks";
// import Shapes from "../../components/svgs/Shapes";
// import Waves from "../../components/ui/Waves";
import bg from "../../assets/bg-star.png";
import CenterIcon from "../../components/ui/CenterIcon";
import { Icon } from "@iconify/react";
import HeroSvg from "./HeroSvg";
const MainSection = ({ title = false }) => {
    const { token } = useContext(AuthContext);

    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin relative">
                {/* <div
                    className="right-0 top-0 bottom-0 h-full w-full z-0 absolute opacity-100 dark:opacity-10 smooth"
                    style={{
                        backgroundImage: "url(" + bg + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        // backgroundRepeat: "repeat-y",
                    }}
                ></div> */}
                <Container className="h-full relative z-10 font-ff pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row flex-col h-full space-y-10 md:space-y-0 ">
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="flex md:items-start items-center justify-center flex-col space-y-6 md:space-y-0 font-graphic">
                                {/* <h2 className="font-h1 font-w-bold">منصة</h2> */}
                                <h1 className="font-big before-box relative">
                                    <div className="relative z-10 ">
                                        <div className="pt-3">
                                            <div className="font-w-bold text-orange-600">
                                                <span>شِيك - وُالِت </span>
                                                <span className="font-h2 font-w-normal">
                                                    . كُوم
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </h1>
                                {/* <div className="h-20 w-1 bg-yellow-500"></div> */}
                                <h2 className="pt-4 space-y-2 flex-center-both flex-col font-ff">
                                    <div className="font-h2 flex flex-col">
                                        <span>هنوفرلك حجز دروسك و أكواد المنصات</span>
                                        <span>
                                            مــع الــمــدرســــيـن اللي تـــخـــتـــارهــــم
                                        </span>
                                        <span>في المواعيد اللي تناسبك</span>
                                    </div>
                                </h2>
                                {!token ? (
                                    <div className="pt-8 pb-20 md:pb-0 font-alm">
                                        <Button
                                            color="purple"
                                            className="block"
                                            element="Link"
                                            to="/register"
                                        >
                                            <span className="flex-center-both space-x-2 space-x-reverse">
                                                <span>انضم لينا دلوقتي </span>
                                                <span className="flex-center-both bg-primary-container text-purple-500 smooth rounded-full px-2 py-1">
                                                    <Icon
                                                        className="inline"
                                                        icon={"bxs:left-arrow"}
                                                    />
                                                </span>
                                            </span>
                                            {/* <span className="inline text-rose-500">
                                                <CenterIcon icon="" />
                                            </span> */}
                                        </Button>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex items-end justify-center max-w-2xl translate-y-[7%]">
                                {/* <img src={profile} alt={"profile"} className="" /> */}
                                <HeroSvg />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
