import React from "react";

const CtaHomePage = () => {
    return (
        <a
            href="https://www.facebook.com/Shake.wallet.eg"
            className="w-screen block fixed z-[1000] boxShadow-cta p-4 bg-[#ECE4B7] dark:bg-[#FE6239] text-center"
        >
            متفوتش تفاصيلنا واخبارنا علي بيدج الفيسبوك ! خليك شيك
            <span className=" mr-2 text-[#FE6239] dark:text-[#181825] inline-block underline font-bold">
                وخش دلوقتي
            </span>
        </a>
    );
};

export default CtaHomePage;
