import React from "react";

import "./herosvg.css";

const HeroSvg = () => {
    return (
        <svg
            id="Layer_2222"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 700 568"
            style={{
                enableBackground: "new 0 0 700 568",
            }}
            xmlSpace="preserve"
        >
            <path
                className="st0"
                d="M24.7,296.1c32.8,37.2,74.4,51.6,94.9,96.8c14.1,31,7.5,67.6,17.8,100c14.2,44.4,61,74.2,107.6,74.4 c46.6,0.2,91-26.2,118.7-63.8c9.1-12.3,16.9-26.1,29.6-34.6c18.2-12.1,41.8-10.6,63.6-12.5c53.8-4.8,104.7-35,134.7-79.8 c0,0,77.9-91.9,22.7-223.4c-16-38.2-42-73.3-77.8-94.1c-35.8-20.8-98.2-21.3-135-2.4c-39.2,20.1-65.3,64.1-108.3,73.7 c-24.9,5.6-50.6-1.7-75.7-6.4c-35.2-6.7-71.1-10.8-106.6-6.3C69.2,122.9,27,143.9,7.5,191c-6.8,16.5-9.1,34.6-6.4,52.3 C3.6,259.2,9.7,279.2,24.7,296.1z"
            />
            <path
                className="st0"
                d="M347.9,24.6c-9-11.4-23.1-17.5-37.2-21c-19.4-4.8-40.3-5.2-59.2,1.6c-18.9,6.7-35.4,21.1-42.3,39.9 c-7,18.8-3.1,41.7,11.3,55.6c8.1,7.8,19.4,12,30.6,11.9c6.6,0.7,13.4,0,19.6-2.4c13.8-5.3,24-17.4,37.3-24.1 c14.4-7.2,33-8.3,42.7-21.2C359.4,53.4,356.9,36,347.9,24.6z"
            />
            <path
                className="st0"
                d="M87.8,426.8c-1.5-3.1-2.9-6.2-3.8-9.5c-2.9-10.6-0.8-22.7-6.5-32.1c-7.1-11.8-24.7-14-36.7-7.1 c-12,6.9-18.8,20.3-21.7,33.8c-1.8,8.3-2.3,17.1,0.5,25.1c3.3,9.3,10.8,16.5,19,22c9.1,6.1,19.8,10.6,30.8,10.2 c4.9-0.2,9.8-1.5,14.2-3.7c3.4-1.5,6.5-3.8,8.6-6.8c1.5-1.8,2.8-3.8,3.7-6C99.8,443.7,96.3,431.6,87.8,426.8z"
            />
            <path
                className="st1"
                d="M520.3,426.1H177.6c-3.2,0-5.8-2.6-5.8-5.8V200.3c0-3.2,2.6-5.8,5.8-5.8h342.7c3.2,0,5.8,2.6,5.8,5.8v219.9 C526.2,423.4,523.5,426.1,520.3,426.1z"
            />
            <path className="st2" d="M517.4,211.3H180.6v192.3h336.8V211.3z" />
            <path
                className="st2"
                d="M351.1,202.2c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1 C350.2,200.1,351.1,201,351.1,202.2z"
            />
            <path
                className="st2"
                d="M573.9,501.6l-43.5-69.3c-2.5-3.9-6.8-6.3-11.4-6.3H341.4h-9.8H179c-4.6,0-8.9,2.4-11.4,6.3l-43.5,69.3 c-1.2,2-1.7,4.3-1.3,6.5h452.3C575.5,505.9,575.1,503.6,573.9,501.6z"
            />
            <path
                className="st1"
                d="M122.8,508.2c0.1,0.5,0.2,0.9,0.3,1.4l0.7,2.1c1.3,3.8,4.8,6.4,8.9,6.4h198.9h9.8h223.9c4,0,7.6-2.6,8.9-6.4 l0.7-2.1c0.2-0.5,0.3-0.9,0.3-1.4H122.8z"
            />
            <path className="st1" d="M195.4,460.2l2.1-4.7H187l-2.2,4.7H195.4z" />
            <path className="st1" d="M213.9,447l1-2.4l1-2.2h-10.1l-2.1,4.7H213.9z" />
            <path className="st1" d="M199.7,455.6l-2.1,4.7h10.6l2-4.7H199.7z" />
            <path className="st1" d="M198.4,453.6l2.1-4.7h-10.3l-2.2,4.7H198.4z" />
            <path className="st1" d="M412,442.3l1.2,4.7h9l-1.3-4.7H412z" />
            <path className="st1" d="M250.1,455.6l-1.4,4.7h10.7l1.4-4.7H250.1z" />
            <path className="st1" d="M237.6,455.6l-1.7,4.7h10.7l1.4-4.7H237.6z" />
            <path className="st1" d="M225,455.6l-1.9,4.7h10.7l1.7-4.7H225z" />
            <path className="st1" d="M212.3,455.6l-2,4.7H221l1.9-4.7H212.3z" />
            <path className="st1" d="M422.9,442.3l1.3,4.7h8.4l-1.4-4.7H422.9z" />
            <path className="st1" d="M286.4,448.9l-1.2,4.7h8.5l1-4.7H286.4z" />
            <path className="st1" d="M378.4,442.3l0.8,4.7h9.8l-0.9-4.7H378.4z" />
            <path className="st1" d="M284.9,447l1.2-4.7h-8.9l-1.3,4.7H284.9z" />
            <path className="st1" d="M273.8,447l1.3-4.7h-8.4l-1.4,4.7H273.8z" />
            <path className="st1" d="M295.2,447l1-4.7h-8.2l-1.2,4.7H295.2z" />
            <path className="st1" d="M433.3,442.3l1.4,4.7h10.5l-1.4-4.7H433.3z" />
            <path className="st1" d="M242.4,442.3l-1.7,4.7h10l0.7-2.4l0.7-2.3H242.4z" />
            <path className="st1" d="M238.6,447l1.7-4.7h-10l-1.9,4.7H238.6z" />
            <path className="st1" d="M218,442.3l-2,4.7h10.3l1-2.4l0.9-2.2H218z" />
            <path className="st1" d="M401.7,442.3l1,4.7h8.3l-1.2-4.7H401.7z" />
            <path className="st1" d="M390.1,442.3l0.9,4.7h9.8l-1-4.7H390.1z" />
            <path className="st1" d="M263.3,447l1.4-4.7h-10.5l-1.4,4.7H263.3z" />
            <path className="st1" d="M275.6,440.3l1.2-4.3h-8.3l-1.3,4.3H275.6z" />
            <path className="st1" d="M265.2,440.3l1.3-4.3h-10.4l-1.3,4.3H265.2z" />
            <path className="st1" d="M366.9,442.3l0.5,4.7h9.8l-0.8-4.7H366.9z" />
            <path className="st1" d="M308.3,440.3l0.9-4.3h-9.5l-1,4.3H308.3z" />
            <path className="st1" d="M296.7,440.3l1-4.3h-8l-1.1,4.3H296.7z" />
            <path className="st1" d="M286.5,440.3l1.1-4.3h-8.7l-1.2,4.3H286.5z" />
            <path className="st1" d="M482.1,442.3l2,4.7h10.3l-2.1-4.7H482.1z" />
            <path className="st1" d="M216.7,440.3l1.9-4.3h-10l-2,4.3H216.7z" />
            <path className="st1" d="M229,440.3l1.7-4.3h-10l-1.9,4.3H229z" />
            <path className="st1" d="M252.7,440.3l1.3-4.3h-9.4l-1.6,4.3H252.7z" />
            <path className="st1" d="M494.4,442.3l2.1,4.7h10.3l-2.2-4.7H494.4z" />
            <path className="st1" d="M241,440.3l1.6-4.3h-9.7l-1.7,4.3H241z" />
            <path className="st1" d="M445.9,442.3l1.4,4.7h10l-1.7-4.7H445.9z" />
            <path className="st1" d="M307.3,455.6l-0.9,4.7h10.1l0.8-4.7H307.3z" />
            <path className="st1" d="M262.8,455.6l-1.4,4.7h8.6l1.3-4.7H262.8z" />
            <path className="st1" d="M295.3,455.6l-1,4.7h10.1l0.9-4.7H295.3z" />
            <path className="st1" d="M273.4,455.6l-1.3,4.7h9.4l1.2-4.7H273.4z" />
            <path className="st1" d="M284.7,455.6l-1.2,4.7h8.7l1-4.7H284.7z" />
            <path className="st1" d="M321.6,442.3l-0.8,4.7h9.8l0.5-4.7H321.6z" />
            <path className="st1" d="M319.9,440.3l0.7-4.3h-9.5l-0.9,4.3H319.9z" />
            <path className="st1" d="M308.6,448.9l-0.9,4.7h10l0.8-4.7H308.6z" />
            <path className="st1" d="M469.8,442.3l1.9,4.7H482l-2-4.7H469.8z" />
            <path className="st1" d="M329.8,453.6l0.5-4.7h-9.9l-0.8,4.7H329.8z" />
            <path className="st1" d="M457.7,442.3l1.7,4.7h10.2l-1.9-4.7H457.7z" />
            <path className="st1" d="M201.4,447l2.1-4.7h-10.1l-2.2,4.7H201.4z" />
            <path className="st1" d="M425.8,460.2l-1.3-4.7h-9.3l1.2,4.7H425.8z" />
            <path className="st1" d="M275.3,448.9l-1.3,4.7h9.2l1.2-4.7H275.3z" />
            <path className="st1" d="M443.2,440.3l-1.3-4.3h-10.4l1.3,4.3H443.2z" />
            <path className="st1" d="M466.9,440.3l-1.7-4.3h-9.7l1.6,4.3H466.9z" />
            <path className="st1" d="M454.9,440.3l-1.6-4.3h-9.4l1.3,4.3H454.9z" />
            <path className="st1" d="M399.3,440.3l-1-4.3h-9.5l0.9,4.3H399.3z" />
            <path className="st1" d="M387.7,440.3l-0.9-4.3h-9.5l0.7,4.3H387.7z" />
            <path className="st1" d="M479.1,440.3l-1.9-4.3h-10l1.7,4.3H479.1z" />
            <path className="st1" d="M420.3,440.3l-1.2-4.3h-8.7l1.1,4.3H420.3z" />
            <path className="st1" d="M409.4,440.3l-1.1-4.3h-8l1,4.3H409.4z" />
            <path className="st1" d="M414.4,460.2l-1.2-4.7h-8.5l1,4.7H414.4z" />
            <path className="st1" d="M487.6,460.2l-2-4.7h-10.5l1.9,4.7H487.6z" />
            <path className="st1" d="M462.1,460.2l-1.7-4.7h-10.5l1.4,4.7H462.1z" />
            <path className="st1" d="M513.2,460.2l-2.2-4.7h-10.5l2.1,4.7H513.2z" />
            <path className="st1" d="M500.4,460.2l-2.1-4.7h-10.5l2,4.7H500.4z" />
            <path className="st1" d="M474.9,460.2l-1.9-4.7h-10.5l1.7,4.7H474.9z" />
            <path className="st1" d="M403.7,460.2l-1-4.7h-10l0.9,4.7H403.7z" />
            <path className="st1" d="M491.4,440.3l-2-4.3h-10l1.9,4.3H491.4z" />
            <path className="st1" d="M509,447h9.5l-2.6-4.7h-9.1L509,447z" />
            <path className="st1" d="M436.5,460.2l-1.4-4.7h-8.5l1.3,4.7H436.5z" />
            <path className="st1" d="M503.6,440.3l-2.1-4.3h-10l2,4.3H503.6z" />
            <path className="st1" d="M449.3,460.2l-1.4-4.7h-10.7l1.4,4.7H449.3z" />
            <path className="st1" d="M514.7,440.3l-2.5-4.3h-8.5l2.1,4.3H514.7z" />
            <path className="st1" d="M430.7,440.3l-1.3-4.3h-8.3l1.2,4.3H430.7z" />
            <path className="st1" d="M367,460.2l-0.5-4.7h-10.2l0.2,4.7H367z" />
            <path className="st1" d="M215.2,448.9l-1,2.4l-1,2.2h10.5l1.9-4.7H215.2z" />
            <path className="st1" d="M378.3,453.6l-0.8-4.7h-9.9l0.5,4.7H378.3z" />
            <path className="st1" d="M211,453.6l2-4.7h-10.3l-1.1,2.4l-1,2.2H211z" />
            <path className="st1" d="M298.3,442.3l-1,4.7h9.8l0.9-4.7H298.3z" />
            <path className="st1" d="M309.9,442.3L309,447h9.8l0.8-4.7H309.9z" />
            <path className="st1" d="M252.1,448.9l-1.4,4.7h10.6l1.4-4.7H252.1z" />
            <path className="st1" d="M227.6,448.9l-1.9,4.7h10.4l1.7-4.7H227.6z" />
            <path className="st1" d="M264.8,448.9l-1.4,4.7h8.5l1.3-4.7H264.8z" />
            <path className="st1" d="M355.9,448.9l0.2,4.7h10.1l-0.5-4.7H355.9z" />
            <path className="st1" d="M240,448.9l-1.7,4.7h10.4l1.4-4.7H240z" />
            <path className="st1" d="M364.7,440.3l-0.5-4.3h-8.7l0.2,4.3H364.7z" />
            <path className="st1" d="M391.6,460.2l-0.9-4.7h-10l0.8,4.7H391.6z" />
            <path className="st1" d="M344.5,436l-0.2,4.3h9.2l-0.2-4.3H344.5z" />
            <path className="st1" d="M344.3,442.3l-0.2,4.7h9.8l-0.2-4.7H344.3z" />
            <path className="st1" d="M365.4,447l-0.5-4.7h-9.2l0.2,4.7H365.4z" />
            <path className="st1" d="M376.1,440.3l-0.7-4.3h-9.2l0.5,4.3H376.1z" />
            <path className="st1" d="M344,448.9l-0.2,4.7h10.3l-0.2-4.7H344z" />
            <path className="st1" d="M343.8,455.6l-0.2,4.7h10.9l-0.2-4.7H343.8z" />
            <path className="st1" d="M342.4,440.3l0.2-4.3h-8.7l-0.5,4.3H342.4z" />
            <path className="st1" d="M321.9,440.3h9.4l0.5-4.3h-9.2L321.9,440.3z" />
            <path className="st1" d="M390.3,453.6l-0.9-4.7h-9.8l0.8,4.7H390.3z" />
            <path className="st1" d="M296.8,448.9l-1,4.7h10l0.9-4.7H296.8z" />
            <path className="st1" d="M170.9,462.2l-2.3,4.1h11.1l2-4.1H170.9z" />
            <path className="st1" d="M434.6,453.6l-1.4-4.7h-8.5l1.3,4.7H434.6z" />
            <path className="st1" d="M428.4,462.2l1.1,4.1h8.7l-1.2-4.1H428.4z" />
            <path className="st1" d="M412.8,453.6l-1.2-4.7h-8.4l1,4.7H412.8z" />
            <path className="st1" d="M341.6,460.2l0.2-4.7h-10.2l-0.5,4.7H341.6z" />
            <path className="st1" d="M464.9,462.2l1.5,4.1h10.9l-1.6-4.1H464.9z" />
            <path className="st1" d="M497.4,453.6l-2.1-4.7H485l2,4.7H497.4z" />
            <path className="st1" d="M477.8,462.2l1.6,4.1h10.8l-1.7-4.1H477.8z" />
            <path className="st1" d="M319.3,455.6l-0.8,4.7H329l0.5-4.7H319.3z" />
            <path className="st1" d="M174.6,455.6l-2.6,4.7h10.6l2.2-4.7H174.6z" />
            <path className="st1" d="M369.2,462.2l0.5,4.1h10.8l-0.7-4.1H369.2z" />
            <path className="st1" d="M209.5,462.2l-1.7,4.1h10.8l1.6-4.1H209.5z" />
            <path className="st1" d="M341.5,462.2h-10.7l-0.5,4.1h11L341.5,462.2z" />
            <path className="st1" d="M484.8,453.6l-2-4.7h-10.4l1.9,4.7H484.8z" />
            <path className="st1" d="M183.8,462.2l-2,4.1h10.8l1.9-4.1H183.8z" />
            <path className="st1" d="M391.4,448.9l0.9,4.7h10l-1-4.7H391.4z" />
            <path className="st1" d="M439.1,462.2l1.2,4.1h10.8l-1.2-4.1H439.1z" />
            <path className="st1" d="M394,462.2l0.8,4.1h10.3l-0.9-4.1H394z" />
            <path className="st1" d="M447.3,453.6l-1.4-4.7h-10.6l1.4,4.7H447.3z" />
            <path className="st1" d="M459.7,453.6l-1.7-4.7h-10.1l1.4,4.7H459.7z" />
            <path className="st1" d="M381.8,462.2l0.7,4.1h10.3l-0.8-4.1H381.8z" />
            <path className="st1" d="M452,462.2l1.2,4.1h11.1l-1.5-4.1H452z" />
            <path className="st1" d="M182.1,442.3l-2.6,4.7h9.5l2.2-4.7H182.1z" />
            <path className="st1" d="M503.5,462.2l1.9,4.1h10.8l-2-4.1H503.5z" />
            <path className="st1" d="M185.7,436l-2.5,4.3h8.9l2.1-4.3H185.7z" />
            <path className="st1" d="M472.2,453.6l-1.9-4.7h-10.2l1.7,4.7H472.2z" />
            <path className="st1" d="M315.5,466.3l0.7-4.1h-93.9l-1.6,4.1H315.5z" />
            <path className="st1" d="M406.2,462.2l0.9,4.1h8.8l-1-4.1H406.2z" />
            <path className="st1" d="M332.5,447h9.6l0.2-4.7h-9.2L332.5,447z" />
            <path className="st1" d="M523.3,455.6h-10.2l5.2,10.7h11.1L523.3,455.6z" />
            <path className="st1" d="M512.2,453.6h10l-2.6-4.7H510L512.2,453.6z" />
            <path className="st1" d="M510,453.6l-2.2-4.7h-10.3l2.1,4.7H510z" />
            <path className="st1" d="M328.3,466.3l0.5-4.1h-10.6l-0.7,4.1H328.3z" />
            <path className="st1" d="M343.5,462.2l-0.2,4.1h24.4l-0.5-4.1H343.5z" />
            <path className="st1" d="M490.6,462.2l1.7,4.1h10.8l-1.9-4.1H490.6z" />
            <path className="st1" d="M424,453.6l-1.3-4.7h-9.1l1.2,4.7H424z" />
            <path className="st1" d="M417,462.2l1,4.1h9.5l-1.1-4.1H417z" />
            <path className="st1" d="M178.4,448.9l-2.6,4.7h10l2.2-4.7H178.4z" />
            <path className="st1" d="M204.4,440.3l2-4.3h-10l-2.1,4.3H204.4z" />
            <path className="st1" d="M196.6,462.2l-1.9,4.1h10.8l1.7-4.1H196.6z" />
            <path className="st1" d="M341.9,453.6l0.2-4.7h-9.7l-0.5,4.7H341.9z" />
            <path className="st1" d="M378.7,455.6h-10.2l0.5,4.7h10.5L378.7,455.6z" />
            <path
                className="st1"
                d="M410.8,501.1H287.2c-0.5,0-1-0.2-1.3-0.7c-0.3-0.4-0.4-1-0.2-1.5l7.9-25.6c0.2-0.7,0.8-1.2,1.6-1.2H403 c0.7,0,1.4,0.5,1.6,1.2l7.9,25.6c0.2,0.5,0.1,1-0.2,1.5C411.8,500.8,411.4,501.1,410.8,501.1z M295,473.7c-0.1,0-0.1,0-0.2,0.1 l-7.9,25.6c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h123.7c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.2l-7.9-25.6c0-0.1-0.1-0.1-0.2-0.1H295z "
            />
            <path className="st3" d="M409,240.8L274.7,262l129.2,64.6l133.8-19.9L409,240.8z" />
            <path
                className="st4"
                d="M274.7,262v3.1L404,332.6l133.7-23v-2.9l-133.8,19.9L274.7,262z"
            />
            <path
                className="st4"
                d="M324.7,334.7l-2.1,13.7c0,0,55.2,44.3,132.9,22.5l1.2-10.7C388,371.7,344.7,349.4,324.7,334.7z"
            />
            <path
                className="st5"
                d="M488.5,406.6c-7.2-5.7-12.3-14.1-14.7-23c-0.2-0.7-1.1-0.4-1.2,0.1c-0.2-0.2-0.5-0.2-0.8-0.1 c-0.1-0.5-0.9-0.7-1.2-0.1c-4.9,9.6-1.1,20.4,2.2,29.8c0.3,0.7,1.1,0.3,0.8-0.4c-2.6-7.4-5.4-15.7-3.8-23.5 c-0.9,9.9,2.4,20,8.4,27.8c0.4,0.6,1.1-0.1,0.7-0.7c-5.5-7.1-8.3-16.1-8-25.1c0.2,3.5,0.7,7,1.8,10.4c1.7,5.1,4.6,10,7.5,14.5 c0.4,0.7,1.1,0,0.7-0.7c-2.8-4.3-5.2-9-6.9-13.9c-0.7-2.1-1.2-4.3-1.5-6.5c1.7,7.2,5.1,13.9,10.1,19.4c0.5,0.6,1.2,0.1,0.7-0.5 c-5.5-6-8.7-13.7-10.1-21.6c2,7.4,5.7,14.5,10.6,20.5c0.5,0.6,1-0.1,0.5-0.7c-4.8-5.9-7.9-12.8-9.9-20c2.7,7.5,6.1,14.7,12.2,20 c0.6,0.5,1.2-0.1,0.7-0.6c-4.2-3.7-6.9-8.6-9.2-13.5c2.4,4.3,5.4,8.4,8.8,12c0.5,0.6,1-0.3,0.5-0.9c-3-3.2-5.3-6.8-7.5-10.5 c2.3,3.1,4.9,5.9,8,8.2C488.4,407.5,489.1,407.1,488.5,406.6z"
            />
            <path
                className="st5"
                d="M475.8,303.2c-2.8-3.4-6.6-5.6-10.5-7.4c-7.7-3.7-16.3-5.4-24.7-7c-11.8-2.2-23.9-4.3-33.8-11.8 c-0.4-0.3-0.8-0.1-1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.2,0.3-0.3,0.7,0,1c7.2,6.9,16.8,10.3,26.3,12.7c9.6,2.4,19.4,4.1,28.5,7.9 c0.3,0.1,0.6,0.2,0.9,0.4c0.1,0,0.4,0.2,0.4,0.2c0.5,0.2,0.9,0.5,1.4,0.7c1.7,0.9,3.4,1.7,5,2.8c1.9,1.3,3.6,2.9,4.8,4.9 c1.3,2.2,1.8,4.6,1.7,7.1c0,2.6-0.5,5.2-1,7.8c-0.4,2.7-0.8,5.3-1.2,8c-0.8,5.5-1.6,11-2.2,16.6c-1.3,12.2-1.7,24.7,0.5,36.8 c0.2,0.9,1.7,0.7,1.6-0.2c-0.9-11.5,0.1-23.1,1.7-34.5c0.8-5.7,1.8-11.3,2.8-17c0.9-5.3,2.2-10.6,2.6-16 C480.1,311.6,479,307,475.8,303.2z"
            />
            <path
                className="st6"
                d="M470.4,385c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.3-0.1,0.6-0.2,0.9-0.2c0.1,0,0.2,0,0.2,0c-0.2,0,0,0,0.1,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.2,0 c0,0,0.1,0,0.1,0c-0.1,0-0.1,0,0,0c0.2,0,0.4,0.1,0.6-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.4-0.1-0.9-0.5-1c-1.5-0.3-3.1-0.1-4.5,0.6 c-0.4,0.2-0.6,0.7-0.3,1.1C469.5,385,470,385.2,470.4,385z"
            />
            <path
                className="st6"
                d="M470.3,383.4c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.3-0.1,0.6-0.2,0.9-0.2c0.1,0,0.2,0,0.2,0c-0.2,0,0,0,0.1,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.2,0 c0,0,0.1,0,0.1,0c-0.1,0-0.1,0,0,0c0.2,0,0.4,0.1,0.6-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.4-0.1-0.9-0.5-1c-1.5-0.3-3.1-0.1-4.5,0.6 c-0.4,0.2-0.6,0.7-0.3,1.1C469.4,383.4,469.8,383.6,470.3,383.4z"
            />
            <path
                className="st7"
                d="M460.8,328l0.7-5.3l-57.5,9.9l-72.4-37.8l-1.6,8.9l72.5,36.5L460.8,328z"
            />
            <path
                className="st3"
                d="M402.5,340.2L330,303.7l-5.4,31c20,14.7,63.3,37,132.1,25.5l4-32.1L402.5,340.2z"
            />
            <path
                className="st8"
                d="M314.7,382.2c3.2,8.6-3.7,19.2-15.4,23.5c-11.7,4.3-18.4-1.1-23.1-9.1c-6.9-11.6-0.2-17.9,11.5-22.2 C299.4,370.1,311.5,373.6,314.7,382.2z"
            />
            <path
                className="st9"
                d="M197.7,287.1c0,0,29.1,29.9,52.9,83.6c0,0,11.3,29.2,18.7,36.4c0,0-6-6.8,3.2-17.5c6.7-7.9,16.5-12.8,26.9-13.1 c5.8-0.2,11.9,1,15.3,5.9c0,0-38.8-45.7-64.1-85c-10.1-15.7-14.9-28.7-17-38.7c0,0-1.9-2.2-5.7-4.1c-14-6.9-30.4,2.4-32.9,17.8 c-0.2,1.4-0.4,3-0.4,4.6C194.6,276.9,194.4,283.7,197.7,287.1z"
            />
            <path
                className="st10"
                d="M269.3,407.1c0,0,12.7,15.3,41.5-3.1c0,0,17.8-11.9,8.9-32.6c0,0-18.5-13.8-33.1-31.3 c-14.6-17.5-32.6-41.3-47.6-72.7l-3.8-2.3c0,0,2.7,14.9,20.6,40.1c17.8,25.1,50.7,67.2,50.7,67.2l5,6.1c2.9,3.5,3.9,8.2,2.3,12.5 c-2.3,6-8.9,13.1-27.5,14.2l-11.6-18C274.7,387.2,263.4,396.5,269.3,407.1z"
            />
            <path
                className="st9"
                d="M297.4,351.9c0,0-33.4-34.8-56.8-89.9c0,0-4.2-6.7-6.9-9.8C233.8,252.2,254.1,315.1,297.4,351.9z"
            />
            <path
                className="st5"
                d="M236,341.8c7-12.7,22.2-21,36.6-20.3c5.1,0.3,5.1-6.1,0-6.4c-17.1-0.9-34.1,7.7-42.4,22.7 C230.3,337.8,233.5,346.3,236,341.8z"
            />
            <path
                className="st4"
                d="M184.3,365.1h-65.5c-8.7,0-15.8-7.1-15.8-15.8v-34.1c0-8.7,7.1-15.8,15.8-15.8h65.5c8.7,0,15.8,7.1,15.8,15.8 v34.1C200.1,358,193,365.1,184.3,365.1z"
            />
            <path className="st11" d="M141.1,332.2v-12l10.4,6l10.4,6l-10.4,6l-10.4,6V332.2z" />
            <path
                className="st9"
                d="M132.6,220.2c0-20.1-16.3-36.3-36.3-36.3c-20.1,0-36.3,16.3-36.3,36.3c0,14.5,8.5,27.1,20.9,32.9v11.6h30.9V253 C124.1,247.2,132.6,234.7,132.6,220.2z"
            />
            <path
                className="st5"
                d="M112.7,270.6H79.8c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3h32.9c1.6,0,3,1.3,3,3C115.7,269.3,114.4,270.6,112.7,270.6 z"
            />
            <path
                className="st5"
                d="M96.3,287.6c-6.6,0-12-5.4-12-12v-2h24v2C108.3,282.2,102.9,287.6,96.3,287.6z"
            />
            <path
                className="st6"
                d="M112.7,276.6H79.8c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3h32.9c1.6,0,3,1.3,3,3C115.7,275.2,114.4,276.6,112.7,276.6 z"
            />
            <path
                className="st5"
                d="M99.6,264.6h-1.7v-50.5c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v50.5H93v-50.5c0-1.8,1.5-3.3,3.3-3.3 c1.8,0,3.3,1.5,3.3,3.3V264.6z"
            />
            <path
                className="st1"
                d="M39.2,207.8l-1,5.2l15.2,2.9c0.2-1.8,0.5-3.5,0.9-5.2L39.2,207.8z"
            />
            <path
                className="st1"
                d="M51.2,183.8l-3.2,4.2l12.1,9.2c1-1.5,2-2.9,3.2-4.2L51.2,183.8z"
            />
            <path
                className="st1"
                d="M72.4,167.7l-4.7,2.4l6.8,13.5c1.5-0.9,3.1-1.7,4.7-2.4L72.4,167.7z"
            />
            <path
                className="st1"
                d="M154.4,213l-1-5.2l-15.1,2.9c0.4,1.7,0.7,3.4,0.9,5.2L154.4,213z"
            />
            <path
                className="st1"
                d="M144.5,188.1l-3.2-4.2l-12.1,9.1c1.1,1.3,2.2,2.8,3.2,4.2L144.5,188.1z"
            />
            <path
                className="st1"
                d="M124.8,170l-4.7-2.4l-6.8,13.5c1.6,0.7,3.2,1.5,4.7,2.4L124.8,170z"
            />
            <path
                className="st1"
                d="M99,177.7l-0.1-15.4l-5.3,0l0.1,15.3c0.9-0.1,1.7-0.1,2.6-0.1C97.2,177.6,98.1,177.7,99,177.7z"
            />
            <path className="st5" d="M632.8,497.6H479.3v30.3h153.5V497.6z" />
            <path className="st6" d="M494.1,497.6H488v30.3h6.2V497.6z" />
            <path className="st6" d="M503.4,497.6h-6.2v30.3h6.2V497.6z" />
            <path className="st6" d="M614.9,497.6h-6.2v30.3h6.2V497.6z" />
            <path className="st6" d="M624.2,497.6H618v30.3h6.2V497.6z" />
            <path
                className="st9"
                d="M647.6,480.7v-4.7c0-6.6-5.4-12-12-12H498.8v28.7h136.8C642.2,492.7,647.6,487.3,647.6,480.7z"
            />
            <path
                className="st4"
                d="M635.6,459H497.9v2.5v2.5h137.7c6.6,0,12,5.4,12,12v4.7c0,6.6-5.4,12-12,12H497.9v2.5v2.5h137.7 c9.4,0,17-7.6,17-17v-4.7C652.6,466.7,645,459,635.6,459z"
            />
            <path className="st12" d="M638.3,470.5H498.8v2.3h139.5V470.5z" />
            <path className="st12" d="M638.3,477.4H498.8v2.3h139.5V477.4z" />
            <path className="st12" d="M638.3,484.3H498.8v2.3h139.5V484.3z" />
            <path
                className="st9"
                d="M487.1,443.9v-4.2c0-6,4.8-10.8,10.8-10.8h123.2v25.8H497.9C491.9,454.7,487.1,449.8,487.1,443.9z"
            />
            <path
                className="st5"
                d="M497.9,424.3h124v2.3v2.3h-124c-6,0-10.8,4.8-10.8,10.8v4.2c0,6,4.8,10.8,10.8,10.8h124v2.3v2.3h-124 c-8.5,0-15.3-6.9-15.3-15.3v-4.2C482.5,431.2,489.4,424.3,497.9,424.3z"
            />
            <path className="st12" d="M495.5,436.7h125.6v-2.1H495.5V436.7z" />
            <path className="st12" d="M495.5,442.9h125.6v-2.1H495.5V442.9z" />
            <path className="st12" d="M495.5,449.1h125.6v-2.1H495.5V449.1z" />
            <path
                className="st5"
                d="M239.5,102.5c5.8,0,10.5,4.6,10.5,10.2v1.5c2.1,5.9,3.4,13.7,3.4,22.2s-1.3,16.2-3.4,22.2v1.5 c0,5.6-4.7,10.2-10.5,10.2c-7.7,0-13.9-15.2-13.9-33.9C225.6,117.7,231.9,102.5,239.5,102.5z"
            />
            <path
                className="st6"
                d="M230.4,136.8c0,11.4,0.9,20.7,2,20.7c1.1,0,2-9.3,2-20.7c0-11.4-0.9-20.7-2-20.7 C231.2,116.1,230.4,125.4,230.4,136.8z"
            />
            <path
                className="st1"
                d="M264.7,121v1.4v28.9v1.4c-0.7,7.8-7.9,15-17.5,17.7v-19v-28.9v-19C256.8,106,264,113.2,264.7,121z"
            />
            <path
                className="st4"
                d="M247.2,173.9c1.6,0,2.9-1.2,2.9-2.8v-68.6c0-1.5-1.3-2.8-2.9-2.8c-1.6,0-2.8,1.2-2.8,2.8v68.6 C244.3,172.6,245.6,173.9,247.2,173.9z"
            />
            <path
                className="st5"
                d="M185,102.5c-5.8,0-10.5,4.6-10.5,10.2v1.5c-2.1,5.9-3.4,13.7-3.4,22.2s1.3,16.2,3.4,22.2v1.5 c0,5.6,4.7,10.2,10.5,10.2c7.7,0,13.9-15.2,13.9-33.9C198.9,117.7,192.7,102.5,185,102.5z"
            />
            <path
                className="st6"
                d="M194.2,136.8c0,11.4-0.9,20.7-2,20.7c-1.1,0-2-9.3-2-20.7c0-11.4,0.9-20.7,2-20.7 C193.3,116.1,194.2,125.4,194.2,136.8z"
            />
            <path
                className="st1"
                d="M159.8,121v1.4v28.9v1.4c0.7,7.8,7.9,15.1,17.5,17.7v-19v-28.9v-19C167.7,106,160.5,113.2,159.8,121z"
            />
            <path
                className="st4"
                d="M177.3,173.9c-1.6,0-2.8-1.2-2.8-2.8v-68.6c0-1.5,1.3-2.8,2.8-2.8c1.6,0,2.9,1.2,2.9,2.8v68.6 C180.2,172.6,178.9,173.9,177.3,173.9z"
            />
            <path
                className="st1"
                d="M247.5,60.7c-5.7-4.7-13.2-7.7-20.2-9.4c-7.3-1.8-14.9-2.2-22.3-1.4c-2.2,0.3-4.5,0.6-6.7,1.1 c-1.5,0.3-3.2,0.6-4.6,1.2c-1.5,0.7-2.9,1.5-4.4,2.2c-3.9,2-7.9,4-11.8,5.9c-1.6,0.8-1.4,3.1,0,3.9c3.6,2.2,7.8,2.5,11.9,1.8 c2.9-0.5,5.5-1.5,8.3-2.5c4.9-1.7,10.3-2.5,15.5-2.6c4.6,0,9.3,0.7,13.6,2.5c6.3,2.7,13.9,4.5,20.2,0.7 C248.2,63.6,248.6,61.7,247.5,60.7z"
            />
            <path
                className="st4"
                d="M213.2,47.4L213.2,47.4c-0.3-0.1-0.6,0-1,0c-0.3,0-0.7,0-1,0l0,0.1c-32.7,1-58.9,27.9-58.9,60.8v36.9 c0,4.1,3.3,7.4,7.4,7.4v-36.4l-0.2-0.9c-2.5-14.8,0.7-30.1,9.7-42.1c4.2-5.6,9.9-10.8,17.6-14.7c0.1,0,0.2-0.1,0.3-0.1 c15.7-7.7,34.3-7.7,50,0c0.1,0,0.2,0.1,0.3,0.1c7.8,3.8,13.5,9.1,17.7,14.7c9,12,12.1,27.3,9.6,42l-0.2,0.9v36.4 c4.1,0,7.4-3.3,7.4-7.4v-36.9C272.1,75.3,245.9,48.5,213.2,47.4z"
            />
            <path className="st2" d="M662.2,179.8h-96.6v139.7h96.6V179.8z" />
            <path className="st1" d="M651.8,200.3h-75.7v3.1h75.7V200.3z" />
            <path className="st1" d="M651.8,214.5h-75.7v3.1h75.7V214.5z" />
            <path className="st1" d="M651.8,228.7h-75.7v3.1h75.7V228.7z" />
            <path className="st1" d="M651.8,242.8h-75.7v3.1h75.7V242.8z" />
            <path className="st1" d="M651.8,257h-75.7v3.1h75.7V257z" />
            <path className="st1" d="M651.8,271.2h-75.7v3.1h75.7V271.2z" />
            <path className="st9" d="M651.8,305.7h-75.7v0.9h75.7V305.7z" />
            <path
                className="st1"
                d="M586.4,292.3c3.9,0.7,7.8,0.8,11.7,0.5c3.6-0.3,7.4-0.9,10.8-2.3c2.4-1,5-3.7,3.3-6.4c-1.5-2.4-5.2-2.7-7.7-2.1 c-2.9,0.7-5.4,2.6-7.2,4.9c-1.8,2.4-3,5.7-0.9,8.3c1.9,2.4,5.4,2.8,8.2,2.4c1.6-0.2,3.1-0.7,4.5-1.5c0.6-0.4,1.2-0.8,1.8-1.3 c0.8-0.6,1.5-1.4,2.6-1.6c1.2-0.2,1.5,0.8,2.3,1.4c0.5,0.5,1.2,0.6,1.9,0.5c0.9-0.1,1.6-0.5,2.4-0.8c1-0.4,2-0.5,3.1-0.5 c4.6,0.1,9.2,0.7,13.8,1.1c0.8,0.1,0.8-0.7,0-0.8c-3.4-0.3-6.8-1.1-10.3-1.3c-1.6-0.1-3.2-0.4-4.7-0.2c-1.5,0.1-2.7,0.9-4.1,1.2 c-1.6,0.3-1.8-1.3-3.1-1.7c-1.1-0.4-2.4-0.1-3.3,0.6c-1.2,0.8-2.1,1.8-3.4,2.5c-1.4,0.8-3,1.2-4.6,1.3c-2.6,0.2-6.4-0.6-7-3.7 c-0.5-2.8,1.8-5.7,3.8-7.3c2.1-1.7,4.9-2.8,7.6-2.3c1.3,0.2,3,0.8,3.3,2.3c0.4,1.7-1.1,3-2.5,3.7c-1.6,0.8-3.4,1.2-5.1,1.5 c-1.9,0.4-3.8,0.7-5.7,0.9c-3.8,0.3-7.6,0.5-11.4-0.1C586,291.3,585.6,292.1,586.4,292.3z"
            />
            {/* <g className="animate-[bounce_10s_infinite]"> */}
            <path
                className="st2"
                d="M694.5,264.6l-17-9.2l5-9.2c1.1-2,3.6-2.7,5.6-1.6l9.8,5.3c2,1.1,2.7,3.6,1.6,5.6L694.5,264.6z"
            />
            <path className="st4" d="M693.3,266.7l-17-9.2l-49,90.4l17,9.2L693.3,266.7z" />
            <path className="st3" d="M687.8,263.7l-6.1-3.3l-49,90.4l6.1,3.3L687.8,263.7z" />
            <path className="st5" d="M697.5,263.7l-21-11.4l-2.2,4.1l21,11.4L697.5,263.7z" />
            <path className="st2" d="M644.3,357.1l-17-9.2l-1.7,15.4l6.7,3.6L644.3,357.1z" />
            <path className="st4" d="M632.3,366.9l-6.7-3.6l-1.1,10.1L632.3,366.9z" />
            {/* </g> */}
            <path
                className="st13 animate-[spin_10s_ease-in-out_infinite]"
                d="M87.4,107.6l17.4,17.4"
            />
            <path
                className="st13 animate-[spin_5s_ease-in-out_infinite]"
                d="M105.1,107.6L87.8,125"
            />
            <path
                className="st13 animate-[spin_10s_ease-in-out_infinite]"
                d="M671.8,368.2l17.4,17.4"
            />
            <path
                className="st13 animate-[spin_5s_ease-in-out_infinite]"
                d="M689.5,368.2l-17.4,17.4"
            />
            <path
                className="st14 animate-[spin_3s_ease-in-out_infinite]"
                d="M38.4,307.3l15.4,15.4"
            />
            <path
                className="st14 animate-[spin_3s_ease-in-out_infinite]"
                d="M54.1,307.3l-15.4,15.4"
            />
            <path
                className="st13 animate-[bounce_3s_ease-in-out_infinite]"
                d="M30.2,179.8c0,6.9-5.6,12.6-12.6,12.6s-12.6-5.6-12.6-12.6c0-6.9,5.6-12.6,12.6-12.6 C24.6,167.2,30.2,172.8,30.2,179.8z"
            />
            <path
                className="st13 animate-[bounce_2s_ease-in-out_infinite]"
                d="M522.2,170.1c6.9,0,12.6-5.6,12.6-12.6c0-6.9-5.6-12.6-12.6-12.6c-6.9,0-12.6,5.6-12.6,12.6 C509.7,164.5,515.3,170.1,522.2,170.1z"
            />
            <path
                className="st14 animate-[bounce_1s_ease-in-out_infinite]"
                d="M579.6,140c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2 C578.2,136.8,579.6,138.2,579.6,140z"
            />
            <path
                className="st14 animate-[bounce_3s_ease-in-out_infinite]"
                d="M271.5,33.5c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2C270.1,30.3,271.5,31.7,271.5,33.5z "
            />
            <path
                className="st4"
                d="M67.7,438.9v7.6c-10.2,0.6-18.2,9-18.2,19.3v6.4c0,10.5,8.5,19.1,19,19.3c3.8,20.7,21.9,36.4,43.7,36.4 c24.6,0,44.5-19.9,44.5-44.5v-44.5H67.7z M55.7,472.2v-6.4c0-6.8,5.3-12.5,12-13v30.7c0,0.6,0,1.2,0,1.8 C61,484.7,55.7,479,55.7,472.2z"
            />
            <path
                className="st2 animate-[bounce_3s_ease-in-out_infinite]"
                d="M134.5,388.3c-5.7-2.9-13.1-1.5-17.6,2.9c-0.8-6.5-7-11.4-13.5-12.4c-6.5-1-13,1.3-18.7,4.6 c-5.6,3.2-10.8,7.6-13.1,13.6c-2.3,6-1,13.7,4.3,17.3c2.1,1.5,4.9,1.9,7.4,1.5c0.3,2,1.1,4,2.5,5.6c4.4,5.4,13.5,5.9,18.5,1.1 c3.8,3.4,9.6,4.2,14.2,2.1c1-0.5,1.9-1.1,2.8-1.8c-0.3,4.5-2,8.8-4.9,12.3c5.5-1.9,10.2-6,12.9-11.2c1.7-3.4,2.6-7.3,2.4-11.1 c4.3-0.8,8.2-4,9.6-8.2C143.3,398.4,140.1,391.2,134.5,388.3z"
            />
            <path
                className="st4"
                d="M334,121.3L334,121.3c-0.2,0-0.5,0-0.7,0c-4.7,0-8.6,3.9-8.6,8.6c0,4.7,3.9,8.6,8.6,8.6c0.2,0,0.5,0,0.7,0 c4.4-0.4,7.9-4.1,7.9-8.6C341.9,125.4,338.4,121.7,334,121.3z"
            />
            <path
                className="st4 animate-[spin_10s_ease-in-out_infinite]"
                d="M364.1,129.9c0-1.5-0.6-2.9-1.6-3.9c-1-1-2.4-1.6-3.9-1.6h-3.2l-0.3-1.1c-0.4-1.4-1-2.8-1.7-4.1l-0.5-1l2.2-2.2 c2.2-2.2,2.2-5.7,0-7.9c-1-1-2.4-1.6-3.9-1.6c-1.5,0-2.9,0.6-3.9,1.6l-2.2,2.2l-1-0.5c-1.3-0.7-2.7-1.3-4.1-1.7l-1.1-0.3v-3.2 c0-2.6-1.9-4.9-4.4-5.4c-0.4-0.1-0.7-0.1-1.1-0.1c-1.5,0-2.9,0.6-3.9,1.6c-1,1-1.6,2.4-1.6,3.9v3.2l-1.1,0.3c-1.4,0.4-2.8,1-4.1,1.7 l-1,0.5l-2.2-2.2c-2.2-2.2-5.7-2.2-7.9,0c-1,1-1.6,2.4-1.6,3.9s0.6,2.9,1.6,3.9l2.2,2.2l-0.5,1c-0.7,1.3-1.3,2.7-1.7,4.1l-0.3,1.1 h-3.2c-3.1,0-5.6,2.5-5.6,5.6c0,1.5,0.6,2.9,1.6,3.9c1,1,2.4,1.6,3.9,1.6h3.2l0.3,1.1c0.4,1.4,1,2.8,1.7,4.1l0.5,1l-2.2,2.2 c-2.2,2.2-2.2,5.7,0,7.9c1,1,2.4,1.6,3.9,1.6c1.5,0,2.9-0.6,3.9-1.6l2.2-2.2l1,0.5c1.3,0.7,2.7,1.3,4.1,1.7l1.1,0.3v3.2 c0,3.1,2.5,5.6,5.6,5.6c0.4,0,0.7,0,1.1-0.1l0.2,0c1-0.2,1.9-0.7,2.7-1.5c1-1.1,1.6-2.4,1.6-3.9v-3.2l1.1-0.3c1.4-0.4,2.8-1,4.1-1.7 l1-0.5l2.2,2.3c1,1,2.4,1.6,3.9,1.6s2.9-0.6,3.9-1.6s1.6-2.4,1.6-3.9c0-1.5-0.6-2.9-1.6-3.9l-2.2-2.2l0.5-1c0.7-1.3,1.3-2.7,1.7-4.1 l0.3-1.1h3.2C361.6,135.4,364.1,132.9,364.1,129.9z M344.8,140.5c-2.7,2.9-6.3,4.6-10.2,5c-0.4,0-0.9,0.1-1.3,0.1 c-8.6,0-15.6-7-15.6-15.7c0-8.6,7-15.7,15.6-15.7c0.4,0,0.9,0,1.3,0.1c3.9,0.3,7.5,2.1,10.2,5c2.7,2.9,4.2,6.7,4.2,10.6 C349,133.8,347.5,137.6,344.8,140.5z"
            />
            <path
                className="st4"
                d="M571.7,371.7L571.7,371.7c-0.1,0.1-0.1,0.3-0.2,0.4c-1.1,2.9,0.3,6.1,3.2,7.2c2.9,1.1,6.1-0.3,7.2-3.2 c0.1-0.1,0.1-0.3,0.1-0.4c0.8-2.8-0.7-5.8-3.4-6.8C576,367.8,572.9,369.1,571.7,371.7z"
            />
            <path
                className="st4 animate-[spin_5s_ease-in-out_infinite]"
                d="M583.9,355.4c-0.9-0.3-1.9-0.3-2.8,0.1c-0.9,0.4-1.6,1.1-1.9,2l-0.7,1.9l-0.7-0.1c-1-0.1-1.9,0-2.9,0.1 l-0.7,0.1l-0.8-1.9c-0.8-1.8-3-2.7-4.8-1.8c-0.9,0.4-1.6,1.1-1.9,2c-0.3,0.9-0.3,1.9,0.1,2.8l0.8,1.9l-0.6,0.5 c-0.7,0.6-1.4,1.3-2,2.1l-0.5,0.6l-1.9-0.7c-1.6-0.6-3.4,0-4.4,1.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.9-0.3,1.9,0.1,2.8 c0.4,0.9,1.1,1.6,2,1.9l1.9,0.7l-0.1,0.7c-0.1,1,0,1.9,0.1,2.9l0.1,0.7l-1.9,0.8c-1.8,0.8-2.7,3-1.8,4.8c0.4,0.9,1.1,1.6,2,1.9 c0.9,0.3,1.9,0.3,2.8-0.1l1.9-0.8l0.5,0.6c0.6,0.7,1.3,1.4,2.1,2l0.6,0.5l-0.7,1.9c-0.7,1.9,0.2,4,2.1,4.7c0.9,0.3,1.9,0.3,2.8-0.1 c0.9-0.4,1.6-1.1,1.9-2l0.7-1.9l0.7,0.1c1,0.1,1.9,0,2.9-0.1l0.7-0.1l0.8,1.9c0.8,1.8,3,2.7,4.8,1.8c0.9-0.4,1.6-1.1,1.9-2 c0.3-0.9,0.3-1.9-0.1-2.8l-0.8-1.9l0.6-0.5c0.7-0.6,1.4-1.3,2-2.1l0.5-0.6l1.9,0.7c1.9,0.7,4-0.2,4.7-2.1c0.1-0.2,0.1-0.4,0.2-0.7 l0-0.1c0.1-0.7,0-1.4-0.3-2c-0.4-0.9-1.1-1.6-2-1.9l-1.9-0.7l0.1-0.7c0.1-1,0-1.9-0.1-2.9l-0.1-0.7l1.9-0.8c0.9-0.4,1.6-1.1,1.9-2 c0.3-0.9,0.3-1.9-0.1-2.8c-0.4-0.9-1.1-1.6-2-1.9c-0.9-0.3-1.9-0.3-2.8,0.1l-1.9,0.8l-0.5-0.6c-0.6-0.7-1.3-1.4-2.1-2l-0.6-0.5 l0.7-1.9C586.7,358.2,585.8,356.1,583.9,355.4z M585.9,369.6c1.1,2.3,1.4,4.9,0.6,7.4c-0.1,0.3-0.2,0.5-0.3,0.8 c-2,5.3-7.9,7.9-13.2,5.9c-5.3-2-7.9-7.9-5.9-13.2c0.1-0.3,0.2-0.5,0.3-0.8c1.1-2.3,3-4.1,5.4-5c2.4-1,5-1,7.5,0 C582.8,365.5,584.8,367.3,585.9,369.6z"
            />
        </svg>
    );
};

export default HeroSvg;
