import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import http from "../../../services/httpServices";

import Container from "../../../components/ui/Container";
import LoadingIcon from "../../../components/ui/LoadingIcon";
import SideTitle from "../../../components/ui/SideTitle";
import CourseCard from "../../../components/ui/CourseCard";
import auth from "../../../services/authServices";
import Button from "../../../components/ui/Button";
import CenterIcon from "../../../components/ui/CenterIcon";

const Categorie = ({
    titleFirst = false,
    titleLast,
    isSubCategorie = false,
    id = 1,
    count = "",
    api = `/api/categories/${id}/sellables`,
    className = "",
    grid = "normal",
    noCoursesPlaceholder = `سيتم أضافه ${titleFirst} ${titleLast} قريبا`,
    coursesList = false,
    isPrepaid = false,
    onPrepaidClick = null,
    initialShowingCourses = true,
}) => {
    const [courses, setCourses] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showCourses, setShowCourses] = useState(initialShowingCourses);

    if (!className.includes("space-x")) {
        if (grid === "normal") {
            className += ` grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10`;
        } else {
            className += ` grid-cols-1 lg:grid-cols-2 gap-5`;
        }
    }

    const [placeholder, setPlaceholder] = useState(
        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
            <span className="flex-center-both space-x-3 space-x-reverse">
                <LoadingIcon className={"font-h1 text-teal-600 dark:text-teal-400"} />
                <span>يتم الآن تحميل المحاضرات</span>
            </span>
        </div>
    );
    const getCourses = async (getAll = false) => {
        try {
            setIsLoading(true);
            if (!showAll) {
                const token = auth.getToken();
                const config = auth.getAuthConfig(token);
                count = getAll ? "all" : count;
                const link = count ? `${api}/${count}` : api;
                const { data } = await http.get(link, config);

                setCourses([]);
                setCourses(data);
                if (data.length < 1) {
                    setPlaceholder(
                        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                            <span className="flex-center-both space-x-3 space-x-reverse">
                                <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                                    <Icon icon="carbon:data-error" />
                                </span>
                                <span>{noCoursesPlaceholder}</span>
                            </span>
                        </div>
                    );
                }
                getAll && setShowAll(true);
            }
            setIsLoading(false);
        } catch (error) {
            setPlaceholder(
                <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        if (coursesList) {
            setCourses(coursesList);
        } else {
            getCourses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursesList]);
    return (
        <Container>
            {titleFirst ? (
                <div className="flex items-center md:space-x-20 md:space-x-reverse flex-col md:flex-row space-y-8 md:space-y-0">
                    <div className=" w-max flex ">
                        <SideTitle first={titleFirst} last={titleLast} />
                        {isLoading ? (
                            <LoadingIcon className="text-4xl  text-blue-700 m-5" />
                        ) : null}{" "}
                    </div>
                    <div className="pt-2">
                        <Button
                            className="flex-center-both space-x-2 space-x-reverse border-blue-500 text-blue-500 smooth group bg- border-0 underline"
                            onClick={() => setShowCourses(!showCourses)}
                        >
                            <span className="">{showCourses ? "اخفاء" : "عرض"} المحاضرات</span>
                            <CenterIcon
                                className={`transform smooth text-blue-500 ${
                                    showCourses ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                                }`}
                                icon="bxs:up-arrow"
                            />
                        </Button>
                    </div>
                </div>
            ) : (
                ""
            )}

            {showCourses ? (
                <div
                    className={`g-teal-400 smooth clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 grid ${className}`}
                >
                    <>
                        {courses.length < 1 && placeholder}
                        {courses.length > 0 &&
                            courses.map((course) => {
                                return (
                                    <CourseCard
                                        key={course.id}
                                        {...course}
                                        isPrepaid={isPrepaid}
                                        onPrepaidClick={onPrepaidClick}
                                    />
                                );
                            })}
                    </>
                </div>
            ) : (
                <div className="flex-center-both">
                    <div
                        className="bg-slate-500 border-slate-500 clr-text-primary smooth px-20 py-t-2 pb-2 font-h1 bg-opacity-5 rounded-md border-2 cursor-pointer"
                        onClick={() => setShowCourses(!showCourses)}
                    >
                        ....
                    </div>
                </div>
            )}
        </Container>
    );
};

export default Categorie;
