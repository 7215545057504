import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import CourseContext from "../../context/CourseContext";
import QRCode from "react-qr-code";

const CenterSubscriptionTokenCard = () => {
    const { user } = useContext(AuthContext);
    const { course } = useContext(CourseContext);
    return (
        <div>
            <div className="mx-4 rounded-2xl shadow-large overflow-hidden border border-secondary-container smooth clr-text-primary -mt-20 relative z-30 bg-primary-container">
                <>
                    <div className="py-20 flex-center-both space-y-4 flex-col">
                        <div className="flex-center-both">
                            <QRCode
                                // size={256}
                                // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={JSON.stringify({
                                    // course_name: course.name,
                                    course_id: course.id,
                                    user_id: user.id,
                                    subscription_id: course.subscription_id,
                                    subscription_serial: course.serial,
                                    subscription_pin_code: course.pin_code,
                                })}
                                // viewBox={`0 0 256 256`}
                            />
                        </div>
                        <div className="flex-center-both flex-col text-center space-y-2">
                            <div className="font-w-bold"> اسم المحاضرة :</div>
                            <div className="font-w-bold font-h1">{course.name}</div>
                        </div>
                        <div className="h-1 w-3/4 rounded-md bg-secondary-container smooth"></div>
                        <div className="flex-center-both flex-col text-center">
                            <div className="font-w-bold">الاسم :</div>
                            <div className="font-w-bold font-h1">{user.full_name}</div>
                        </div>
                        <div className="flex-center-both flex-col text-center space-y-2">
                            <div className="font-w-bold">تسلسل الحجز :</div>
                            <div className="font-w-bold font-h1 bg-blue-500 text-white rounded-md px-5">
                                {course.serial}
                            </div>
                        </div>
                        <div className="flex-center-both flex-col text-center space-y-2">
                            <div className="font-w-bold">الرمز السري للحجز :</div>
                            <div className="font-w-bold font-h1 bg-rose-500 text-white rounded-md px-5">
                                {course.pin_code}
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
};

export default CenterSubscriptionTokenCard;
