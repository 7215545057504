import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "shakewallet-adminss";
export const domainName = "shake-wallet.com";
export const isManualPayment = false;
export const isCouponable = false;
export const isMultiYear = true;
export const isCodes = false;
export const isBtns = true;
export const isLoginTokens = true;
export const profileStatistics = false;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "purple";
export const headerSectionColor = "purple";
export const navbarIconColor = "text-orange-500";
export const navbarDisclosureColor = "orange";

export const progressBarColor = "orange";
export const loadingBarColor = "orange";

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = false;
export const prepaidInfoColor = "yellow";

export const autoChangeRandomNames = false;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "yellow";
export const registerPageColor = "indigo";

export const isAnalytics = false;
export const isAbleChangingPassword = false;

export const isMultiAdmin = true;

export const isCategories = true;

export const isSubjectSubscriptionable = true;

export const isAccountCreationRequest = false;

export const showExamStatisticsTable = false;

export const isOtpEnabled = false;

export const isWalletEnabled = true;

export const isOnlySubscribeFromWallet = false;

export const isCenterUsers = false;

export const isForgetPassword = false;

export const isAdminUsers = false;

export const isSubscribeBySubjectTeacher = false;

export const isMigrationFromInsertAuto = false;
export const isChargeInsertAuto = false;
export const isInsertAutoTypes = false;

export const isCourseRenamedToMonthlySubscriptions = false;

export const isCommunity = false;

export const isCustomUserExamLimit = false;

export const isCenterApi = false;

export const isEssayQuestions = false;

export const isAdminCourseStatistics = false;

export const isSearch = false;

export const isRegisterationMiddleName = true;
export const isRegisterationForthName = true;
export const isAdminAbleToChangeInfo = true;
