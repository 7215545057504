import React, { useContext } from "react";
import TeacherContext from "../../context/TeacherContext";
import Container from "../../components/ui/Container";
import TeacherInfoSection from "../../sections/course/TeacherInfoSection";
import Categorie from "../../sections/home/CoursesSection/Categorie";
import AuthContext from "../../context/AuthContext";
import SideTitle from "../../components/ui/SideTitle";
import { Icon } from "@iconify/react";
import CoursesWithCategories from "../../sections/home/CoursesSection/CoursesWithCategories";
import { useLocation, useParams } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const TeacherHome = () => {
    const { teacher } = useContext(TeacherContext);
    const { user, token } = useContext(AuthContext);

    const query = useQuery();
    const courseType = query.get("course_type");

    return (
        <Container className="relative py-0">
            <div className="flex flex-col md:flex-row-reverse space-y-10 md:space-y-0 md:space-x-10">
                <div className=" md:basis-1/3 relative -mt-52">
                    <TeacherInfoSection />
                </div>
            </div>
            <div className="w-full">
                {/* <TeachersGrid subjectId={subject.id} isSubject={false} /> */}
                {/* {isSubscribeBySubjectTeacher ? (
                    <SubjectsGrid teacherId={teacher.id} isTeacher={true} />
                ) : (
                    ""
                )} */}
                {courseType !== "center_course" ? (
                    <>
                        <CoursesWithCategories
                            titleFirst="أكواد"
                            titleLast="المنصه"
                            api={`/api/teachers/${teacher.id}/api_courses${
                                token ? `/years/${user.year}/courses` : `/courses`
                            }`}
                        />
                        <CoursesWithCategories
                            titleFirst="حجز محاضرات"
                            titleLast="السنتر"
                            api={`/api/teachers/${teacher.id}/center_courses${
                                token ? `/years/${user.year}/courses` : `/courses`
                            }`}
                        />
                    </>
                ) : (
                    <>
                        <CoursesWithCategories
                            titleFirst="حجز محاضرات"
                            titleLast="السنتر"
                            api={`/api/teachers/${teacher.id}/center_courses${
                                token ? `/years/${user.year}/courses` : `/courses`
                            }`}
                        />
                        <CoursesWithCategories
                            titleFirst="أكواد"
                            titleLast="المنصه"
                            api={`/api/teachers/${teacher.id}/api_courses${
                                token ? `/years/${user.year}/courses` : `/courses`
                            }`}
                        />
                    </>
                )}

                {/* <div className="flex items-center md:space-x-20 md:space-x-reverse flex-col md:flex-row space-y-8 md:space-y-0">
                    <SideTitle first="حجز محاضرات" last={"السنتر"} />
                    <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                        <span className="flex-center-both space-x-3 space-x-reverse">
                            <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                                <Icon icon="twemoji:soon-arrow" />
                            </span>
                            <span>{"سيتم اضافة هذه الخدمه قريبًا"}</span>
                        </span>
                    </div>
                </div> */}

                {/* <Categorie
                    titleFirst="حجز محاضرات"
                    titleLast="السنتر"
                    api={`/api/teachers/${teacher.id}/center_courses${
                        token ? `/years/${user.year}/courses` : `/courses`
                    }`}
                /> */}
            </div>
        </Container>
    );
};

export default TeacherHome;
