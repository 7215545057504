import React, { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import Button from "../../components/ui/Button";
import RemoteTable from "../../components/ui/RemoteTable";
import http from "../../services/httpServices";
// import { isMultiYear } from "../../services/defaultSettings";
// import { getYearPlaceHolder } from "../../services/yearSevices";
import InputField from "../../components/form/elements/InputField";
import { handleInputChange } from "../../services/formServices";
import auth from "../../services/authServices";
import { Link } from "react-router-dom";

const SubscriptionsSheet = () => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.serial,
            sortable: true,
            sortField: "serial",
        },
        {
            name: "اسم الطالب",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    row.user.full_name
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
            // sortable: true,
            // sortField: "user.full_name",
        },
        {
            name: "الرمز السري",
            reorder: true,
            selector: (row) => row.pin_code,
        },
        {
            name: "رقم هاتف الطالب",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        element="Link"
                        to={`../user_profile/${row.user.id}`}
                    >
                        0{row.user.phone}
                    </Link>
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
        },
    ];

    const initialState = {
        course_id: 0,
    };

    const [filterData, setFilterData] = useState(initialState);
    const [data, setData] = useState(initialState);

    const [courses, setCourses] = useState([]);

    const getCourses = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        let result;

        result = await http.get(`/api/courses/options`, config);
        const { data: response } = result;
        setCourses(response);
    };

    useEffect(() => {
        getCourses();
        // if ((isSubjectSubscriptionable && data.teacher_id) || !isSubjectSubscriptionable) {
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="course_id"
                        type="select"
                        placeholder="اختر المحاضرة"
                        options={courses}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                    isDisabled={data.course_id == 0 ? true : false}
                >
                    {/* {console.log(filterData.exam_id == 0 ? true : false)} */}
                    بحث
                </Button>
                {filterData.course_id == 0 ? (
                    <div className="flex-center-both w-full">
                        <div className="p-20 bg-yellow-500 bg-opacity-10 rounded-md border-2 border-yellow-500 clr-text-primary smooth text-center font-h3">
                            يرجى اختيار المحاضرة و الضغط على بحث
                        </div>
                    </div>
                ) : (
                    <>
                        <RemoteTable
                            paginationPerPage={1000}
                            sortCol="serial"
                            api={`/api/subscriptions/paginate`}
                            columns={columns}
                            filterData={filterData}
                        />
                    </>
                )}
            </div>
        </AdminContainer>
    );
};

export default SubscriptionsSheet;
