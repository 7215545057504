import React, { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import CourseContext from "../../context/CourseContext";
import InputField from "../../components/form/elements/InputField";
import { Icon } from "@iconify/react";
import modal from "../../services/modalServices";
import Button from "../../components/ui/Button";
import { handleFormErrors, handleInputChange } from "../../services/formServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";

const MigrateApiCourseSubscription = () => {
    const { user } = useContext(AuthContext);
    const { course, setCourse } = useContext(CourseContext);

    const [data, setData] = useState({
        phone: "",
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleCharge = async () => {
        setIsLoading(true);
        setErrors({});

        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        const toSendData = { ...data };
        toSendData["phone"] = parseInt(data["phone"]);
        try {
            const { data: response } = await http.post(
                `/api/course_api/${course.id}/migrate_subscription`,
                { ...toSendData },
                config
            );
            modal.message({
                title: "تم شحن حسابك بنجاح",
                text: `تم شحن حسابك بنجاح`,
                callback: () => {
                    //   setIsDisabled(true);
                    //   setIsDisabled();
                    setCourse({
                        ...course,
                        migrated_to_phone_number: response.migrated_to_phone_number,
                        remote_subscription_id: response.remote_subscription_id,
                    });
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors, false);
        }
    };

    return (
        <div>
            <div className="mx-4 rounded-2xl shadow-large overflow-hidden border border-secondary-container smooth clr-text-primary -mt-20 relative z-30 bg-primary-container py-20">
                <>
                    <div className="font-w-bold font-h1 text-center">
                        شحن هذه المحاضرة على حسابك في المنصة
                    </div>

                    {course.migrated_to_phone_number ? (
                        <div className="flex-center-both flex-col space-y-2">
                            <div className="font-h2">تم شحن هذه المحاضرة على المنصة على</div>
                            <div className="font-w-bold rounded-md bg-purple-500 text-white px-4 py-2 font-h3">
                                {course.migrated_to_phone_number}
                            </div>
                            <div className="underline text-purple-500 font-w-bold font-h3">
                                <a href={course.course_link} className="text-center block">
                                    {" "}
                                    الذهاب للكورس <br /> {course.course_link}
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className=" flex flex-col space-y-2">
                            <div className="flex flex-center-both">
                                <div className="flex flex-col space-y-4 w-full max-w-lg">
                                    <div className="pb-5 text-center space-y-3">
                                        <div className="font-w-bold font-h2">شحن الحصة</div>
                                        <div>
                                            اكتب رقم تليفون الحساب بتاعك على منصة المدرس عشان
                                            تتشحنلك المحاضرة على حسابك اللي في منصة المدرس
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-end space-x-2 space-x-reverse  pb-4 pt-2 w-full">
                                        <InputField
                                            id="phone"
                                            className="w-full"
                                            placeholder="رقم تليفون حسابك"
                                            icon={
                                                <span className="flex-center-both mb-1">
                                                    <Icon icon="ph:phone-duotone" />
                                                </span>
                                            }
                                            data={data}
                                            setData={setData}
                                            errors={errors}
                                            onChange={handleInputChange}
                                            type="text"
                                        />
                                    </div>
                                    <div className="flex-center-both">
                                        <Button
                                            color="cyan"
                                            onClick={handleCharge}
                                            isLoading={isLoading}
                                        >
                                            شحن الحصة لهذا الرقم
                                        </Button>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};

export default MigrateApiCourseSubscription;
