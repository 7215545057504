import React, { useContext, useEffect, useState } from "react";
import { isEmptyObject } from "jquery";
import TeacherLine from "./TeacherLine";
import AuthContext from "../../context/AuthContext";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import { years } from "../../services/yearSevices";
import { isMultiYear } from "../../services/defaultSettings";
import InputField from "../../components/form/elements/InputField";
import { handleInputChange } from "../../services/formServices";
import Container from "../../components/ui/Container";
import { ReactComponent as Hash } from "../../assets/hash.svg";
const TeachersSlider = ({ isCenterCourses }) => {
    const [filterData, setFilterData] = useState({
        year: 3,
        department_id: 0,
    });
    const [departments, setDepartments] = useState([]);
    // console.log(departments);
    const [categorieApi, setCategorieApi] = useState("");
    const { user } = useContext(AuthContext);
    const [first, setFirst] = useState([]);
    const [second, setSecond] = useState([]);
    const [third, setThird] = useState([]);
    const toUseYears = years.filter((value, index) => index < 3);

    const getSubjects = async () => {
        const { data } = await http.get(`api/with_year_id/subjects/options`);
        // console.log(data);
        const FilterThird = data.filter((subject) => subject.year === "3");
        const FilterTwo = data.filter((subject) => subject.year === "2");
        const FilterOne = data.filter((subject) => subject.year === "1");

        setFirst(FilterOne.map((item) => [item.text, item.value]));
        setSecond(FilterTwo.map((item) => [item.text, item.value]));
        setThird(FilterThird.map((item) => [item.text, item.value]));

        //    console.log(data.filter((subject)=>subject.year === '3'))
        //    console.log(FilterThird.map((item) => [item.text, item.value]))
    };

    // console.log(filterData.year);
    const getDepartments = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        const { data } = await http.get(`api/years/${filterData.year}/departments/options`, config);

        setDepartments([]);
        if (data.length < 1) {
            setFilterData({ ...filterData, department_id: 0 });
        } else {
            setFilterData({ ...filterData, department_id: data[0]["value"] });
        }
        setDepartments(data);
    };

    // useEffect(() => {
    //     if (!isEmptyObject(user) && user.year && user.year !== filterData.year) {
    //         setFilterData({
    //             ...filterData,
    //             year: user.year,
    //         });
    //     }
    //     getDepartments();
    // }, [user]);

    useEffect(() => {
        getSubjects();

        getDepartments();
    }, [filterData.year]);

    useEffect(() => {
        if (filterData.department_id > 0) {
            setCategorieApi(`/api/departments/${filterData.department_id}/courses`);
        }
    }, [filterData.department_id]);

    return (
        <div className="relative mb-20 overflow-hidden" id="teachers">
            {/* <ScrollingProvider>
                <SectionHead title={"محتوى المنصة"} /> 
            </ScrollingProvider> */}
            <div className="">
                <Container className="">
                    <div className=" relative font-big font-w-bold w-fit mx-auto font-ff text-center flex justify-center items-center">
                        <Hash className=" absolute -right-10 -z-10 w-11 sm:w-auto" />
                        <div>
                            <span>مدرسين </span>
                            <span className="text-orange-500">شِيك - وُالِت</span>
                        </div>
                    </div>
                    <div className="flex-center-both">
                        <div className="flex-center-both flex-col sm:flex-row  px-10 sm:py-8 rounded-2xl space-y-4 sm:space-y-0 sm:gap-10 bg-[#ECE4B7] dark:bg-orange-500 smooth clr-text-primary">
                            {isMultiYear ? (
                                <InputField
                                    onChange={handleInputChange}
                                    data={filterData}
                                    setData={setFilterData}
                                    type="select"
                                    options={toUseYears}
                                    id="year"
                                    placeholder="اختر الصف الدراسي"
                                    // errors={errors}
                                />
                            ) : (
                                ""
                            )}
                            <InputField
                                onChange={handleInputChange}
                                data={filterData}
                                setData={setFilterData}
                                type="select"
                                options={departments}
                                id="department_id"
                                placeholder="اختر الشعبة"
                            />
                        </div>
                    </div>
                </Container>
                <div className="mt-5 md:mt-14">
                    {filterData.department_id === 0 ? (
                        <Container>
                            <div className="flex-center-both">
                                <div className="border-2 rounded-md border-yellow-300 dark:border-yellow-500 p-10 bg-yellow-100 dark:opacity-5 smooth clr-text-primary">
                                    اختر الشعبة الدراسية اولًا لإختيار المادة !
                                </div>
                            </div>
                        </Container>
                    ) : (
                        <>
                            <div className="w-screen max-h-auto relative  border-black dark:border-[#001F49] border bg-[#FAF3DD] dark:bg-[#181825]">
                                {" "}
                                <div className="relative z-10">
                                    <TeacherLine
                                        department_id={filterData.department_id}
                                        isCenterCourses={isCenterCourses}
                                    />{" "}
                                </div>
                                <div className="bg-pattern absolute inset-0" />
                            </div>
                            {/* <SubjectsGrid department_id={filterData.department_id} /> */}
                            {/* <TeachersGrid department_id={filterData.department_id} /> */}
                            {/* <Categorie
                                titleFirst="كورسات"
                                titleLast="الشعبة"
                                noCoursesPlaceholder={"سيتم اضافة الكورسات داخل هذه الشبعة قريبًا"}
                                api={categorieApi}
                            /> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TeachersSlider;
