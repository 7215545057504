import CenterIcon from "../components/ui/CenterIcon";
import modal from "./modalServices";

import { baseURL } from "../config";

export const Icons = {
    video: <CenterIcon className={`text-yellow-500`} icon={"ant-design:video-camera-twotone"} />,
    exam: <CenterIcon className="text-rose-500" nY="px" icon={"ph:exam-duotone"} />,
    book: <CenterIcon className="text-blue-400" nY="px" icon={"icon-park-twotone:book-one"} />,
    hm: <CenterIcon className="text-teal-400" nY="px" icon={"ic:twotone-quiz"} />,
};

export const showPicture = (picture, useBaseUrl = true) => {
    if (!useBaseUrl) {
        modal.message({ icon: `${picture}` });
    } else {
        modal.message({ icon: `${baseURL}/${picture}` });
    }
};

export const videoSource = (source) => {
    return `${baseURL}/videos/${source}`;
};

export const bookSource = (source) => {
    return `${baseURL}/${source}`;
};

export const platforms = [
    { value: "upload", text: "رفع" },
    { value: "youtube", text: "يوتيوب" },
    { value: "vimeo", text: "فيميو" },
    { value: "ink", text: "إنكربت فيديو" },
    { value: "vdocipher", text: "فيديو سايفر" },
];
export const courseTypes = [
    // { value: "platform_course", text: "كورس اونلاين" },
    { value: "center_course", text: "حجز محاضرة سنتر" },
    { value: "api_course", text: "حجز اكواد" },
];
export const getPlatformPlaceHolder = (value) => {
    const platforms = {
        upload: "رفع",
        youtube: "يوتيوب",
        vimeo: "فيميو",
        ink: "إنكربت فيديو",
        vdocipher: "فيديو سايفر",
    };
    return platforms[value];
};
