import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { handleFormErrors, handleFormSubmit, handleInputChange } from "../../services/formServices";
import auth from "../../services/authServices";
import modal from "../../services/modalServices";
import page from "../../services/pageServices";
import http from "../../services/httpServices";
import InputIcon from "../../components/form/elements/InputIcon";
import {
    isRegisterationForthName,
    isRegisterationMiddleName,
} from "../../services/defaultSettings";
import InputField from "../../components/form/elements/InputField";
import Form from "../../components/form/elements/Form";
import Button from "../../components/ui/Button";

const FullNameEdit = ({ user, isAdmin = false, afterSuccess = () => null }) => {
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false); // State to control form visibility
    const { setUser: authUser, user: contextUser, setIsFullName } = useContext(AuthContext);

    const [data, setData] = useState({
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        father_phone: user?.father_phone || "",
        isAdmin,
    });
    let inputFields = [];

    const padPhoneNumber = (phoneNumber) => {
        return phoneNumber.toString().padStart(11, "0");
    };

    useEffect(() => {
        setData((prevData) => ({
            ...prevData,
            father_phone: padPhoneNumber(prevData.father_phone),
        }));

        setIsFormVisible(true);
    }, [user]);

    if (isAdmin) {
        inputFields = [
            {
                id: "toggle_form_visibility",
                placeholder: " تعديل بيانات الطالب بالكامل",
                type: "switch",
                value: isFormVisible,
                onChange: () => setIsFormVisible(!isFormVisible),
            },
            ...inputFields,
        ];
    }
    if (isFormVisible) {
        inputFields = [
            ...inputFields,
            {
                id: "first_name",
                placeholder: `الاسم الأول`,
                icon: <InputIcon icon="icon-park-solid:edit-name" />,
                isArabic: true,
            },
        ];

        if (!isAdmin) {
            if (isRegisterationMiddleName) {
                inputFields = [
                    ...inputFields,
                    {
                        id: "middle_name",
                        placeholder: `الاسم ${!isRegisterationForthName ? "الأوسط" : "الثاني"}`,
                        icon: <InputIcon icon="icon-park-solid:edit-name" />,
                        isArabic: true,
                    },
                ];
            }
            if (isRegisterationForthName) {
                inputFields = [
                    ...inputFields,
                    {
                        id: "third_name",
                        placeholder: `الاسم الثالث`,
                        icon: <InputIcon icon="icon-park-solid:edit-name" />,
                        isArabic: true,
                    },
                ];
            }
        }
        inputFields = [
            ...inputFields,
            {
                id: "last_name",
                placeholder: isAdmin ? "باقي الأسم" : "الاسم الأخير",
                icon: <InputIcon icon="icon-park-solid:edit-name" />,
                isArabic: true,
            },
            {
                id: "father_phone",
                placeholder: "رقم هاتف ولي الأمر",
                icon: <InputIcon icon="ant-design:phone-filled" />,
            },
        ];
    }

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, editUserName);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const redirect = () => {
        if (location.state?.from) {
            navigate(location.state.from);
        } else {
            navigate("/me/user");
        }
    };

    const editUserName = async () => {
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const adminToken = auth.getAdminToken();
            const adminConfig = auth.getAdminAuthConfig(adminToken);
            const toSendUser = {
                ...data,
            };
            toSendUser["father_phone"] = parseInt(data["father_phone"]);
            let result = [];
            if (!isAdmin) {
                result = await http.post("api/user/fullname", toSendUser, config);
                modal.message({
                    title: "تم تنفيذ طلبك بنجاح",
                    text: `تم تغيير الاسم إلى : '${result.data.user.full_name}'`,
                    callback: () => {
                        setIsFullName(true);
                        auth.authenticate(token, result.data.user);
                        authUser({
                            ...result.data.user,
                        });
                        redirect();
                    },
                });
            } else {
                result = await http.post(`api/userInfoEdit/${user.id}`, toSendUser, adminConfig);
                modal.message({
                    title: "تم تنفيذ طلبك بنجاح",
                    text: `تم تغيير الاسم إلى : '${result.data.user.full_name}'`,
                    callback: () => {
                        afterSuccess();
                    },
                });
            }
            setIsLoading(false);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    useEffect(() => {
        const currentTitle = page.getCurrentTitle();
        page.setTitle("تعديل بيانات الأسم بالكامل");
        return () => {
            page.setTitle(currentTitle);
        };
    }, []);

    return (
        <>
            {isRegisterationMiddleName ? (
                <div className="w-full">
                    <Form className="space-y-6 max-w-lg mx-auto" onSubmit={handleSubmit}>
                        <div className="flex flex-col space-y-8">
                            {inputFields.map((input, index) => {
                                return (
                                    <InputField
                                        key={index}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                "",
                                                data,
                                                setData,
                                                setErrors,
                                                input.isArabic
                                            )
                                        }
                                        data={data}
                                        setData={setData}
                                        errors={errors}
                                        className="w-full"
                                        {...input}
                                    />
                                );
                            })}
                        </div>
                        {isFormVisible && (
                            <div>
                                <Button isLoading={isLoading} color="purple" className="w-full">
                                    تعديل
                                </Button>
                            </div>
                        )}
                    </Form>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default FullNameEdit;
