import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import auth from "../../../services/authServices";

import http from "../../../services/httpServices";

const CheckFullName = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { isCheckedFullName, setIsCheckedFullName, isFullName, setIsFullName, setUser } =
        useContext(AuthContext);

    const checkFullNameChanged = async () => {
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data: result } = await http.get("/api/user", config);
            // console.log(result.user.full_name);
            const { user: userData } = result;
            setUser({ ...userData });
            if (!userData.is_full_name_changed) {
                setIsCheckedFullName(true);
                navigate("/me/user/change_fullName");
            } else {
                setIsFullName(true);
            }
        } catch (e) {}
    };
    useEffect(() => {
        const token = auth.getToken();
        if (!isFullName && !location.pathname.includes("change_fullName") && token) {
            if (isCheckedFullName) {
                navigate("/me/user/change_fullName");
            } else {
                checkFullNameChanged();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, isFullName]);
    return <></>;
};

export default CheckFullName;
