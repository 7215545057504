import SeeMoreDescription from "../components/ui/SeeMoreDescription";
export const a2e = (value) => value.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export const description = (value, isSeeMore = false, lastRowElement = "", exceedingNumber = 149) =>
    value &&
    (isSeeMore ? (
        <SeeMoreDescription value={value} exceedingNumber={exceedingNumber} />
    ) : (
        value.split(/(?:\r\n|\r|\n)/g).map((line, i) => (
            <span key={i}>
                {line}
                {i === value.split(/(?:\r\n|\r|\n)/g).length - 1 ? lastRowElement : ""}
                <br />
            </span>
        ))
    ));

export const getUnit = (
    value,
    plural = "",
    sinuglar = "",
    two = "",
    isFeminine = false,
    returnOriginalValue = false
) => {
    const oneUnit = sinuglar + (isFeminine ? " واحدة" : " واحد");
    const parsedValue = parseInt(value);
    const returnValue = returnOriginalValue ? value : parsedValue;
    if (parsedValue === 0) {
        return { value: 0, label: "" };
    } else if (parsedValue === 1) {
        return { value: "", label: oneUnit };
    } else if (parsedValue === 2) {
        return { value: "", label: two };
    } else if (parsedValue < 11) {
        return { value: returnValue, label: plural };
    } else {
        return { value: returnValue, label: sinuglar };
    }
};

export const printUnit = (
    value,
    plural = "",
    sinuglar = "",
    two = "",
    isFeminine = false,
    returnOriginalValue = false
) => {
    if (plural === "دقيقة") {
        sinuglar = "دقيقة";
        two = "دقيقتان";
        plural = "دقائق";
        isFeminine = true;
    } else if (plural === "ثانية") {
        sinuglar = "ثانية";
        two = "ثانيتان";
        plural = "ثواني";
        isFeminine = true;
    } else if (plural === "مرة") {
        sinuglar = "مرة";
        two = "مرتان";
        plural = "مرات";
        isFeminine = true;
    } else if (plural === "سؤال") {
        sinuglar = "سؤال";
        two = "سؤالان";
        plural = "اسئلة";
        isFeminine = false;
    } else if (plural === "ساعة") {
        sinuglar = "ساعة";
        two = "ساعاتان";
        plural = "ساعات";
        isFeminine = true;
    } else if (plural === "فيديو") {
        sinuglar = "فيديو";
        two = "فيديوهان";
        plural = "فيديوهات";
        isFeminine = false;
    } else if (plural === "امتحان") {
        sinuglar = "امتحان";
        two = "امتحانان";
        plural = "امتحانات";
        isFeminine = false;
    } else if (plural === "محاضرة") {
        sinuglar = "محاضرة";
        two = "محاضرتان";
        plural = "محاضرات";
        isFeminine = true;
    } else if (plural === "مشاهدة") {
        sinuglar = "مشاهدة";
        two = "مشاهدتان";
        plural = "مشاهدات";
        isFeminine = true;
    } else if (plural === "كود") {
        sinuglar = "كود";
        two = "كود";
        plural = "أكواد";
        isFeminine = false;
    } else if (plural === "محاضرة") {
        sinuglar = "محاضرة";
        two = "محاضرتان";
        plural = "محاضرات";
        isFeminine = true;
    } else if (plural === "جنيه") {
        sinuglar = "جنيه";
        two = "جنيهان";
        plural = "جنيهات";
        isFeminine = false;
    }
    return (
        getUnit(parseInt(value), plural, sinuglar, two, isFeminine)["value"] +
        " " +
        getUnit(parseInt(value), plural, sinuglar, two, isFeminine)["label"]
    );
};
