import React from "react";

import Container from "../../components/ui/Container";
import CenterIcon from "../../components/ui/CenterIcon";

import "./AboutSection.css";
import Button from "../../components/ui/Button";
import { Icon } from "@iconify/react";

const AboutSection = ({ setIsCenterCourses }) => {
    return (
        <>
            <section className="relative font-ff py-40">
                {/* <div className="bg-slate-100 dark:bg-slate-900 smooth clr-text-primary py-40 rotate-[-10deg] rounded-2xl scale-[2] translate-y-[15%] absolute top-80 right-0 left-0 w-full z-0"></div> */}
                {/* <div className="lg:py-48 md:py-40 sm:py-36 py-32"></div> */}
                <div className="rounded-2xl bg-slate-100 dark:bg-slate-900 smooth clr-text-primary relative z-10 to-slope-edges py-20">
                    <Container>
                        <div className="font-big font-w-bold">
                            <span>ليه تختار </span>
                            <span className="text-orange-700 dark:text-orange-400 smooth">
                                شِيك - وُالِت
                            </span>
                        </div>
                        <div className="py-2 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 items-center justify-center gap-10">
                            <div className="flex items-center justify-between flex-col bg-primary-container smooth clr-text-primary rounded-lg py-16  px-5 space-y-5 h-full">
                                <div className="flex-center-both space-x-2 space-x-reverse font-w-bold">
                                    <CenterIcon
                                        className="text-orange-500 font-h1 -translate-y-1"
                                        icon="heroicons:wallet"
                                    />
                                    <span className="font-h3">هتوفر فلوسك</span>
                                </div>
                                <div className="md:px-24 text-center">
                                    تقدر تحجز من خلالنا منصات مُدرسين تختارهم بخصم ممتاز
                                </div>
                            </div>
                            <div className="flex items-center justify-between flex-col bg-primary-container smooth clr-text-primary rounded-lg py-16  px-5 space-y-5 h-full">
                                <div className="flex-center-both space-x-2 space-x-reverse font-w-bold">
                                    <CenterIcon
                                        className="text-orange-500 font-h1 -translate-y-1"
                                        icon="material-symbols:more-time-rounded"
                                    />
                                    <span className="font-h3">وفرنا وقتك</span>
                                </div>
                                <div className="md:px-24 text-center">
                                    تقدر تحجز دلوقتي من البيت و كمان في المواعيد اللي تحبها مع
                                    المدرس اللي يناسبك
                                </div>
                            </div>
                            <div className="flex items-center justify-between flex-col bg-primary-container smooth clr-text-primary rounded-lg py-16  px-5 space-y-5 h-full">
                                <div className="flex-center-both space-x-2 space-x-reverse font-w-bold">
                                    <CenterIcon
                                        className="text-orange-500 font-h1 -translate-y-1"
                                        icon="uil:comment-verify"
                                    />
                                    <span className="font-h3">هنروق عليك</span>
                                </div>
                                <div className="md:px-24 text-center">
                                    وفرنالك اسرع خدمه دعم فني مع توفير كل المدرسين لكل المواد
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                {/* <div className="py-80"></div> */}
                <div className="left-0 md:right-0 top-0 md:w-full w-[200vw] z-0 opacity-100 absolute -mt-1">
                    {/* <img src={bgtop} alt="top" className="w-full" /> */}
                </div>
            </section>
            <section className="font-ff relative py-20">
                <Container>
                    <div className="font-big font-w-bold text-center">
                        <span>متفوتكش </span>
                        <span className="text-orange-500">خَدمَاتنا</span>
                    </div>
                    <div className="flex md:flex-row flex-col md:space-y-0 space-y-6 justify-evenly items-center">
                        <div className="max-w-md clr-text-secondary smooth font-h3">
                            بخطوات بسيطة تقدر تسجل في منصتنا مجانًا و تظبط وقتك و تختار المحتوى اللي
                            يناسبك و تشترك في أي وقت براحتك
                        </div>
                        <div className="font-alm">
                            <Button color="purple" className="block" element="Link" to="/register">
                                <span className="flex-center-both space-x-2 space-x-reverse">
                                    <span>انضم لينا دلوقتي </span>
                                    <span className="flex-center-both bg-primary-container text-purple-500 smooth rounded-full px-2 py-1">
                                        <Icon className="inline" icon={"bxs:left-arrow"} />
                                    </span>
                                </span>
                                {/* <span className="inline text-rose-500">
                                                <CenterIcon icon="" />
                                            </span> */}
                            </Button>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-20">
                        <div className="rounded-lg py-8 px-5 gradient-orange smooth clr-text-primary flex flex-col items-center justify-between space-y-10">
                            <div className="flex flex-col space-y-6 w-full">
                                <div className="font-w-bold font-h2">
                                    <span>شراء </span>
                                    <span className="text-orange-500">كود منصه </span>
                                    <span>اونلاين </span>
                                </div>
                                <div className="flex flex-col space-y-1 w-full">
                                    <span>- ليك مكان في المنصه اللي هتحجز فيها</span>
                                    <span>- ليك خصم لو حجزت من عندنا</span>
                                </div>
                            </div>
                            <div className="w-full flex items-end flex-col space-y-2">
                                <div>
                                    <span className="font-w-bold">سعر الاشتراك : </span>
                                    <span className="">على حسب اختيارك</span>
                                </div>
                                <div>
                                    <Button
                                        className="block font-alm"
                                        element="a"
                                        href="#teachers"
                                        color="orange"
                                    >
                                        احجز كود منصه دلوقتي
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-lg py-8 px-5 gradient-orange smooth clr-text-primary flex flex-col items-center justify-between space-y-10">
                            <div className="flex flex-col space-y-6 w-full">
                                <div className="font-w-bold font-h2">
                                    <span>حجز </span>
                                    <span className="text-orange-500">مكانك بالسنتر </span>
                                    <span>اونلاين </span>
                                </div>
                                <div className="flex flex-col space-y-1 w-full">
                                    <span>- ليك مكان في السنتر و عضوية المنصة</span>
                                    <span>- مبقاش لازم انك تروح السنتر اكتر من مره عشان تحجز</span>
                                    <span>- اعرف اقرب مكان ليك موجود فيه مدرسك</span>
                                </div>
                            </div>
                            <div className="w-full flex items-end flex-col space-y-2">
                                <div>
                                    <span className="font-w-bold">سعر الاشتراك : </span>
                                    <span className="">على حسب اختيارك</span>
                                </div>
                                <div>
                                    <Button
                                        className="block font-alm"
                                        element="a"
                                        onClick={() => setIsCenterCourses(true)}
                                        href="#teachers"
                                        color="orange"
                                    >
                                        احجز كود منصه دلوقتي
                                    </Button>
                                    {/* <Button
                                        className="block font-alm"
                                        // element="a"
                                        isDisabled={true}
                                        // href="#teachers"
                                        color="orange"
                                    >
                                        سيتم توفير هذه الخدمه قريبًا
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default AboutSection;
