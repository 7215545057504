import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import InputIcon from "../../components/form/elements/InputIcon";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import modal from "../../services/modalServices";
import AdminContainer from "../../components/ui/AdminContainer";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
} from "../../services/formServices";
import AdminForm from "../../components/ui/AdminForm";
import { years } from "../../services/yearSevices";
import { isMultiYear } from "../../services/defaultSettings";

const initialState = {
    phone: "",
    course_id: [],
    action: "attach",
    year: 3,
};

const EditCourseAllowedUsers = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [courses, setCourses] = useState([]);

    // const [result, setResult] = useState({
    //     users: {},
    //     not_found_users: [],
    // });
    // const [showResult, setShowResult] = useState(false);
    const [inputFields, setInputFields] = useState([]);

    useEffect(() => {
        let fields = [
            {
                id: "phone",
                placeholder: "ارقام الهاتف",
                icon: <InputIcon icon="ant-design:phone-filled" />,
            },
        ];
        if (isMultiYear) {
            fields = [
                ...fields,
                {
                    id: "year",
                    placeholder: "اختر الصف الدراسي",
                    isDisabled: data.is_all || data.platform_subscription,
                    type: "select",
                    options: years,
                },
            ];
        } else {
            fields = [
                ...fields,
                {
                    id: "year",
                    placeholder: "نوع الكورس",
                    isDisabled: data.is_all || data.platform_subscription,
                    type: "select",
                    options: [
                        {
                            value: "3",
                            text: "الكورسات",
                        },
                        {
                            value: "4",
                            text: "كورسات المحاضرات",
                        },
                    ],
                },
            ];
        }
        fields = [
            ...fields,
            {
                id: "course_id",
                placeholder: "اختر المحاضرة",
                multiple: true,
                options: courses,
                type: "select",
            },
            {
                id: "action",
                placeholder: "",
                options: [
                    { value: "attach", text: "السماح لهم بالحجز" },
                    { value: "detach", text: "إلغاء السماح لهم" },
                ],
                type: "select",
            },
        ];

        setInputFields(fields);
    }, [courses, data.is_all, data.permanent, data.platform_subscription]);

    const getCourses = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            `/api/years/${data.year}/allowable_courses/options`,
            config
        );
        setCourses(response);
        // const currentData = { ...data };
        // currentData["course_id"] = response[0]["value"];
        // currentData["course_id"].push(response[0]["value"] + "");
        // setData(currentData);
    };
    useEffect(() => {
        getCourses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.year]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            subscribeUser();
        });
    };
    const subscribeUser = async () => {
        try {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);

            const toSendData = { ...data };
            // toSendData["phone"] = parseInt(data["phone"]);
            const { data: response } = await http.post(
                "/api/course_allowed_users",
                toSendData,
                config
            );

            modal.message({
                title: "تم تنفيذ طلبك بنجاح",
                text: response.message,
                // button: "مشاهدة نتيجة العملية",
                // callback: () => {
                //     window.scrollTo(0, 0);
                // },
            });
            // setResult(response.result);
            // setShowResult(true);
            // setData(initialState);
            setIsLoading(false);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <div className="space-y-10">
            {/* {showResult && (
                <SubscriptionResult
                    result={result}
                    successfulPlaceholder={`محاضرات تم\nتفعيلها`}
                    duplicatedPlaceholder={`محاضرات مشترك\nبها بالفعل`}
                />
            )} */}
            <AdminContainer>
                <AdminForm onSubmit={handleSubmit} isLoading={isLoading} buttonText="تنفيذ">
                    <div className="-my-8 clr-text-secondary font-small">
                        للسماح لأكثر من رقم اترك <span className="underline">مسافة</span> بين كل رقم
                        و الآخر
                        <br />
                        أو <span className="underline">انسخ</span> الأرقام من ملف اكسل
                    </div>
                    {inputFields.map((input, key) => (
                        <InputField
                            key={key}
                            onChange={handleChange}
                            data={data}
                            setData={setData}
                            errors={errors}
                            {...input}
                        />
                    ))}
                </AdminForm>
            </AdminContainer>
        </div>
    );
};

export default EditCourseAllowedUsers;
