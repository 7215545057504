import React, { useContext, useState } from "react";

import MainSection from "../sections/home/MainSection";
import { ScrollingProvider } from "../context/ScrollingContext";

import YearsSection from "../sections/home/YearsSection";

import AboutSection from "../sections/home/AboutSection";
import AuthContext from "../context/AuthContext";

import TeachersSlider from "../sections/home/TeachersSlider";
import CtaHomePage from "./CtaHomePage";

const Home = () => {
    // const [isAuth, setIsAuth] = useState(false);
    const { token } = useContext(AuthContext);
    // if (auth.isAuth()) {
    //     setIsAuth(true);
    // }
    // const navigate = useNavigate();
    // const token = auth.getToken();
    // if (token) {
    //     if (user.year) {
    //         navigate(`/years/${user.year}`);
    //     }
    // }

    const [isCenterCourses, setIsCenterCourses] = useState(false);
    return (
        <>
            <CtaHomePage />
            <MainSection />
            {token ? (
                ""
            ) : (
                <ScrollingProvider>
                    <AboutSection setIsCenterCourses={setIsCenterCourses} />
                </ScrollingProvider>
            )}
            <TeachersSlider isCenterCourses={isCenterCourses} />

            {/* <CoursesSection /> */}
            {/* <YearsSection /> */}
        </>
    );
};

export default Home;
