import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import InputIcon from "../../components/form/elements/InputIcon";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import modal from "../../services/modalServices";
import AdminContainer from "../../components/ui/AdminContainer";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
} from "../../services/formServices";
import AdminForm from "../../components/ui/AdminForm";

const initialState = {
    phone: "",
    reduced_ammount: "",
    fetched_ammount: "",
    message: "",
};

const ManualReduceWalletBalance = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isAmmountFetched, setIsAmmountFetched] = useState(false);

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const [inputFields, setInputFields] = useState([]);

    useEffect(() => {
        let fields = [
            {
                id: "phone",
                placeholder: "رقم الهاتف",
                icon: <InputIcon icon="ant-design:phone-filled" />,
            },
            {
                id: "fetch_ammount",
                type: "button",
                placeholder: "معرفة رصيد الطالب",
                onClick: fetchWalletAmmount,
            },
        ];
        if (isAmmountFetched) {
            fields = [
                ...fields,
                {
                    id: "fetched_ammount",
                    type: "html",
                    placeholder: `الرصيد المتاح: ${data.fetched_ammount}`,
                    className: "font-bold text-lg text-green-500",
                },
            ];
        }
        fields = [
            ...fields,
            {
                id: "reduced_ammount",
                placeholder: "المبلغ المنقوص",
                icon: <InputIcon icon="ant-design:money" />,
            },
            {
                id: "message",
                placeholder: "الرسالة",
                icon: <InputIcon icon="ant-design:mail" />,
            },
        ];

        setInputFields(fields);
    }, [isAmmountFetched, data.fetched_ammount, data.phone]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            reduceWalletAmmount();
        });
    };

    const fetchWalletAmmount = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);
        try {
            const { data: response } = await http.post(
                `/api/manual_admin_wallet/get_wallet_balance`,
                { phone: data.phone },
                config
            );
            setData({ ...data, fetched_ammount: response.current_balance });
            setIsAmmountFetched(true);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };
    const reduceWalletAmmount = async () => {
        try {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);

            const toSendData = { ...data };
            // toSendData["phone"] = parseInt(data["phone"]);
            const { data: response } = await http.post(
                "/api/manual_admin_wallet/reduce_wallet_balance",
                toSendData,
                config
            );

            modal.message({
                title: `تم تعديل المبلغ بنجاح`,
                text: response.message,
                icon: "success",

                buttons: {
                    confirm: "حفظ ومتابعة",
                    cancel: "متابعة",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        setData(data);
                    } else {
                        setData(initialState);
                    }
                },
            });
            setIsLoading(false);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    useEffect(() => {
        setIsAmmountFetched(false);
    }, [data.phone]);

    return (
        <div className="space-y-10">
            <AdminContainer>
                <AdminForm onSubmit={handleSubmit} isLoading={isLoading} buttonText="تنفيذ">
                    {inputFields.map((input, key) => (
                        <InputField
                            key={key}
                            onChange={handleChange}
                            data={data}
                            setData={setData}
                            errors={errors}
                            {...input}
                        />
                    ))}
                </AdminForm>
            </AdminContainer>
        </div>
    );
};

export default ManualReduceWalletBalance;
